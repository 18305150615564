<!-- <div class="scroll-container"> -->
    <main id="about-container">
        <div class="container d-flex flex-column justify-content-center align-items-center">
            <div class="header-wrapper">
                <h1>ABOUT</h1>
                <h2>FIFTH DIMENSION</h2>
            </div>
            <div class="objective-wrapper">
                <p>We cover a large range of creative digital projects, platforms, and campaigns to create experiences</p>
            </div>
            <div class="clients-wrapper">
                <h3>
                  Clients that can't live without our services
                </h3>
                <p>
                  We are just a group of normal people who live , walk and breath like you... We are just a bit more creative , definitely funnier more insightful, relatable and we know how to win and make others win.. But what we lack in humbleness we make up for in our 360 marketing services and marketing strategies with Full VR services
                </p>
                <ul class="clients-grid-wrapper">
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Ahmad-Tea.png" title="Ahmed Tea" alt="Ahmed Tea" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Allianz.png" title="Allianz" alt="Allianz" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/future.png" title="Future" alt="Future" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Garnier.png" title="Garnier" alt="Garnier" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/HPD.png" title="HPD" alt="HPD" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/hydeout.png" title="Hydeout" alt="Hydeout" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/krestase.png" title="Krestase" alt="Krestase" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Loreal.png" title="Loreal" alt="Loreal" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Maghrabi.png" title="Maghrabi" alt="Maghrabi" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Miele.png" title="Miele" alt="Miele" />
                  </li>

                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/americana.png" title="Americana" alt="Americana" />
                    </li>

                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Disny.png" title="Disney" alt="Disney" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Dodge.png" title="Dodge" alt="Dodge" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Egyptionsteel.png" title="Egyptian Steel" alt="Egyptian Steel" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/emmar.png" title="Emmar" alt="Emmar" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Fusin.png" title="Fusin" alt="Fusin" />
                    </li>
                </ul>
                <div class="btn-container text-center">
                    <button routerLink="/our-clients" mat-flat-button color="accent" class="next-btn magic-hover magic-hover__square m-5" type="button">
                        SEE MORE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>
            </div>
            <!-- <div class="testimonials-container">
                <h3>
                    What people are saying about us
                </h3>
                <div id="testimonials-swiper" class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <p>
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto voluptatibus ipsa sit neque vero labore quisquam explicabo reprehenderit facere cum."
                            </p>
                            <div class="client-review">
                                John doe - CEO at CompanyX
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <p>
                                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum sunt natus repellendus laudantium veniam suscipit quos, tempora magnam eos ea nesciunt soluta impedit sed laboriosam, similique quo ut blanditiis quis?"
                            </p>
                            <div class="client-review">
                                Mikel Smith - Designer at CompanyY
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <p>
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto voluptatibus."
                            </p>
                            <div class="client-review">
                                Carine Miles - Manager at CompanyZ
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <p>
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur ad, quos cumque corporis magni cum!"
                            </p>
                            <div class="client-review">
                                Frank Mohamed - Director at 8Low
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>

                    <div id="testimonials-nav-btns-container" class="nav-btns-container">
                        <button class="prev-btn magic-hover magic-hover__square" type="button">
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </button>
                        <button class="next-btn magic-hover magic-hover__square" type="button">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </button>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="next-container">
            <h3>
                We’re looking forward to hearing from you
            </h3>
            <div class="next-wrapper">
                <p>Let's Talk</p>
                <h1>
                    <a routerLink="/contact-us" class="magic-hover magic-hover__square">
                        CONTACT US
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->





