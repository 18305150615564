<!-- <div class="scroll-container"> -->
    <main id="reach-container">
        <div class="service-hero-container">
            <div class="service-hero-wrapper d-flex flex-column justify-content-between align-items-center">
                <div class="header-wrapper d-flex flex-column justify-content-center align-items-center">
                    <div class="text-wrapper container">
                        <div class="sectionLogoContainer">
                            <img src="../../../../assets/img/reach/reachLogo.svg" />
                        </div>
                        <h1>SOCIAL MEDIA</h1>
                        <p class="sub-header">THE ABILITY TO ATTRACT</p>
                        <p class="text">
                          Our calendars are filled with your calendars, we speak with one liners and we live in captions to master our craft and make sure our clients get the most impressive results..
                        </p>
                    </div>
                    <div class="icons-Wrapper container">
                        <div id="icons-swiper" class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/reach/reachServiceIcon1.png"/>
                                    </div>
                                    <p>Content Marketing</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/reach/reachServiceIcon2.png"/>
                                    </div>
                                    <p>Social Media Management</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/reach/reachServiceIcon3.png"/>
                                    </div>
                                    <p>Digital Advertising</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/reach/reachServiceIcon4.png"/>
                                    </div>
                                    <p>Consumers Insights</p>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>

                            <!-- <div id="icons-nav-btns-container" class="nav-btns-container">
                                <button class="prev-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                                </button>
                                <button class="next-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronRight"></fa-icon>
                                </button>
                            </div> -->

                        </div>

                    </div>

                </div>
            </div>
        </div>


        <!-- DANONE PRODUCT -->

        <div id="danone-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="danone-particles" class="particles">
                    <div class="danone-shape-right"></div>
                    <div class="danone-shape-left"></div>
                    <div class="danone-shape-top"></div>
                    <div class="danone-bottle"></div>
                </div>
            </div>
            <div id="danone-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/danoneLogo.png" alt="Danone">
                </div>
                <div class="hero-text">
                    <h2>
                        YOGURT FOR ALL
                    </h2>
                    <p>The family friendly strategy has a greater vision of gathering the whole family in one place, we provide them with a warming content to meet their objectives and targeted audience.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- HAVE A TASTE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- HEINZ PRODUCT -->

        <div id="heinz-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="heinz-particles" class="particles">
                    <div class="heinz-tomato3"></div>
                    <div class="heinz-tomato2"></div>
                    <div class="heinz-tomato1"></div>
                    <div class="heinz-tomato"></div>
                </div>
            </div>
            <div id="heinz-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/heinzLogo.png" alt="Heinz">
                </div>
                <div class="hero-text">
                    <h2>
                      Ketching up with the ketchup
                    </h2>
                    <p>A brand that is used by everyone had to have a social media presence that appeals to every one and that was our challenge</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- DANETTE PRODUCT -->

        <div id="danette-product" class="product-container left-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="danette-particles" class="particles">
                    <div class="danette-pack1"></div>
                    <div class="danette-pack2"></div>
                </div>
            </div>
            <div id="danette-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/danetteLogo.png" alt="Danette">
                </div>
                <div class="hero-text">
                    <h2>
                        SIMPLY IRRESISTIBLE
                    </h2>
                    <p>With passion leading our content strategy we use our platforms to create engaging content and build brand image to our favorite.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- HAVE A SPOON -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- VITRAC PRODUCT -->

        <div id="vitrac-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="vitrac-particles" class="particles">
                    <div class="vitrac-fruit4"></div>
                    <div class="vitrac-fruit3"></div>
                    <div class="vitrac-grass"></div>
                    <div class="vitrac-jar"></div>
                    <div class="vitrac-fruit2"></div>
                    <div class="vitrac-fruit1"></div>
                </div>
            </div>
            <div id="vitrac-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/VitracLogo.png" alt="Vitrac">
                </div>
                <div class="hero-text">
                    <h2>
                        NATURALLY DELICIOUS
                    </h2>
                    <p>The authenticity of the brands is combined with a hint of modernism and a lot of creativity. It's all about engaging our fans and building the right image the brand seeks.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- HAVE A TASTE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- KITKAT PRODUCT -->

        <div id="kitkat-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="kitkat-particles" class="particles">
                    <!-- <div class="kitkat-choco1"></div> -->
                    <div class="kitkat-choco4"></div>
                    <div class="kitkat-choco3"></div>
                    <!-- <div class="kitkat-choco2"></div> -->
                    <div class="kitkat-choco5"></div>
                    <div class="kitkat-choco6"></div>
                </div>
            </div>
            <div id="kitkat-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/kitkatLogo.png" alt="Vitrac">
                </div>
                <div class="hero-text">
                    <h2>
                        UNWRAP THE GOODNESS
                    </h2>
                    <p>Comes from everything delicious is how our social content is inspired. KitKat has succeeded to tackle not only your taste buds but also your sense of humor with its light and fun content.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- JOIN THE FUN -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- COSTA COFFEE PRODUCT -->

        <div id="costa-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="costa-particles" class="particles">
                    <div class="costa-drink"></div>
                </div>
            </div>
            <div id="costa-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/costaLogo.png" alt="Costa Coffee">
                </div>
                <div class="hero-text">
                    <h2>
                      It didnt Costa thing
                    </h2>
                    <p>Just a couple of teaspoons of our creative juices got the job done</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- HERO PRODUCT -->

        <div id="hero-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="hero-particles" class="particles">
                    <div class="hero-jar"></div>
                    <div class="hero-tree"></div>
                    <div class="hero-fruits"></div>
                </div>
            </div>
            <div id="hero-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/herojamLogo.png" alt="Hero Jam">
                </div>
                <div class="hero-text">
                    <h2>
                      They needed a Hero too
                    </h2>
                    <p>As much Hero group are heroes to everymom in every household they needed a hero to run there marketing</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- MAGGI PRODUCT -->

        <div id="maggi-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="maggi-particles" class="particles">
                    <div class="maggi-small-pack"></div>
                    <div class="maggi-medium-pack"></div>
                    <div class="maggi-sandwich"></div>
                    <div class="maggi-big-pack"></div>
                </div>
            </div>
            <div id="maggi-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/maggiLogo.png" alt="Maggi">
                </div>
                <div class="hero-text">
                    <h2>
                      Essential to every kitchen
                    </h2>
                    <p>While they offer their shortcuts to great tasting foods in every kitchen, we offer them a shortcut to a great taste in marketing</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- CLOROX PRODUCT -->

        <div id="clorox-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="clorox-particles" class="particles">
                    <div class="clorox-clothes"></div>
                    <div class="clorox-bottle"></div>
                    <div class="clorox-flowers"></div>
                </div>
            </div>
            <div id="clorox-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/cloroxLogo.png" alt="Clorox">
                </div>
                <div class="hero-text">
                    <h2>
                      Perfectly Clean
                    </h2>
                    <p>Perfectly clean content that attracts every eye to a brand that's famous for cleaning clothes to attract every eye</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>


        <!-- NIYA PRODUCT -->

        <!-- <div id="niya-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="niya-particles" class="particles">
                    <div class="niya-player-container">
                        <img src="../../../../assets/img/reach/niyaVideo.jpg" data-toggle="modal" data-target="#video-modal" />
                        <fa-icon [icon]="faPlay"></fa-icon>
                    </div>
                    <div class="niya-mobile-app"></div>
                </div>
            </div>
            <div id="niya-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/niyaLogo.png" alt="Niya">
                </div>
                <div class="hero-text">
                    <h2>
                        Interesting Header
                    </h2>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas temporibus voluptas ex quasi, ab placeat assumenda est doloribus neque, facere beatae qui rerum magnam nemo?</p>
                </div>

                <div class="play-btn-container magic-hover magic-hover__square" data-toggle="modal" data-target="#video-modal">
                    PLAY VIDEO
                    <fa-icon [icon]="faPlay"></fa-icon>
                </div>

                <div class="cta">
                    <button type="button" mat-flat-button>
                        EXPLORE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div> -->


        <!-- ZEEGO CHIPS PRODUCT -->

        <div id="zeego-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="zeego-particles" class="particles">
                    <div class="zeego-snacks"></div>
                    <div class="zeego-desk"></div>
                    <div class="zeego-music"></div>
                </div>
            </div>
            <div id="zeego-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/reach/zeegoLogo.png" alt="Zeego Chips">
                </div>
                <div class="hero-text">
                    <h2>
                      Straight for the Curly
                    </h2>
                    <p>Them curly chips needed a straight-up well crafted content</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- EXPLORE -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <div class="next-container">
            <div class="next-wrapper">
                <p>Next</p>
                <h1>
                    <a routerLink="/innovation" class="magic-hover magic-hover__square">
                        INNOVATION
                    </a>
                </h1>
            </div>
        </div>
    </main>

    <!-- Modal -->
    <div class="modal fade" id="video-modal"  data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                <!-- <h5 class="modal-title" id="videoModalLabel">Modal title</h5> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div class="plyr__video-embed" id="niya-plyr">
                        <iframe
                          src="https://www.youtube.com/embed/fOgpewuU6pA?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                          allowfullscreen
                          allowtransparency
                          allow="autoplay"
                        ></iframe>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
<!-- </div> -->
