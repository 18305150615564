import { Component, OnInit, Input } from '@angular/core';
import {
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  faComment
} from '@fortawesome/free-regular-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

import {magicMouse} from '../../../assets/js/magic_mouse.js';

declare var $;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // Icons
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faArrowRight = faArrowRight;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faComment = faComment;

  scrollbar;


  contactFormHeader: string = `Keep In Touch`;

  constructor() {}

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );

    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);


    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');


    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);

    // animating content
    // Wrap every letter in a span
    const header1 = document.querySelectorAll('.home-section-content h1.section-header');
    header1.forEach((el) => {
      el.innerHTML = el.textContent.replace(/\S/g, '<span class=\'letter d-inline-block\'>$&</span>');
    });


    const welcomeTL = gsap.timeline();
    welcomeTL
        .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
          autoAlpha: 1,
          duration: 1,
          ease: 'none',
        })
        .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
          autoAlpha: 0,
          duration: 1,
          ease: 'none',
        })
        .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
          autoAlpha: 0,
          duration: 2,
          ease: 'none',
        }, '-=2')
        .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: '-100%',
          autoAlpha: 0,
          duration: 0.1,
          ease: 'none',
        })
        .fromTo('#welcome-section-content h1.section-header .letter:nth-child(even)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-300%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1, ease: 'none'}, '-=1')
        .fromTo('#welcome-section-content h1.section-header .letter:nth-child(odd)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-100%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1, ease: 'none'},
                '-=0.75')
        .fromTo('#welcome-section-content h2.section-sub-header',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '100%', autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', autoAlpha: 1, duration: 0.6, ease: 'none'},
                '-=0.2')
        .to('#welcome-section-content .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 1,   y: '0%', duration: 0.45, ease: 'none'});


    $(window).scroll((e) => {
      if($(window).scrollTop() <= 20){
        if ( !homeSwiper.mousewheel.enabled ){
          homeSwiper.mousewheel.enable();
        }
      }
    });

    // $('#home-swiper').swiper.destroy();
    // Initializing swiper carousel
    const homeSwiper = new swiper('#home-swiper', {
      // Optional parameters
      direction: 'vertical',
      speed: 1300,
      grabCursor: false,
      effect: 'slide',
      // coverflowEffect: {
      //   rotate: 30,
      //   slideShadows: false,
      // },
      preventInteractionOnTransition: false,
      // autoplay: {
      //   delay: 2000,
      //   disableOnInteraction: false,
      // },
      // loop: false,
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      //   clickable: true,
      //   // progressbarOpposite: true
      //   // type: 'fraction',
      //   // renderFraction: (currentClass, totalClass) => {
      //   //   return '<span class="' + currentClass + '"></span>' +
      //   //           ' / ' +
      //   //           '<span class="' + totalClass + '"></span>';
      //   // }
      // },
      pagination: false,
      allowTouchMove: false,
      resistance: true,
      resistanceRatio: 0.85,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: {
        invert: false,
        // releaseOnEdges: true,
        sensitivity: 0.1,
        // eventsTarget: '.viewport',
      },
      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },
      breakpoints: {
        // when window width is >= 320px
        640: {
          effect: 'slide',
        }
      },
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      //   draggable: true,
      // },
      scrollbar: false,
      on: {
        init: (): void => {

              gsap.set( $('.nav-btns-container .prev-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              gsap.set( $('.nav-btns-container .next-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});

              gsap.set('.home-section-content', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '-1'});
              gsap.set('#welcome-section-content', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '1'});

              gsap.to($('.swiper-slide-next').find('.img-mask'), {z: 0.1, rotationZ: 0.01, force3D: true,
              y: '-50%', duration: 0.6, ease: 'none'});
              $('#welcome-section-content').addClass('active');
              gsap.set('.home-section-content.active .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 0,   y: '100%', duration: 0.45});
            },


            slideNextTransitionStart: (): void => {
              $('.nav-btns-container .prev-btn').attr('style', '');
              $('.nav-btns-container .next-btn').attr('style', '');
              $('#magicMouseCursor').addClass('is-scrolling-down');
              gsap.set( $('.nav-btns-container .prev-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              gsap.set( $('.nav-btns-container .next-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              $('.nav-btns-container .next-btn').addClass('is-sliding-down');
              $('.home-section-content').removeClass('floating-animation');

              const secContent = document.querySelectorAll('.home-section-content');
              secContent.forEach((el) => {
                $(el).removeClass('active');
              });
              $('.home-section-content').eq(homeSwiper.activeIndex).addClass('active');

              const headersTL = gsap.timeline({ease: 'none'});
              gsap.set('.home-section-content', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '-1'});
              gsap.set('.home-section-content.active', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '1'});
              gsap.set('.home-section-content.active .icon',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 0,   y: '-100%', scaleY: 2, duration: 0.45});
              gsap.set('.home-section-content.active .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 0,   y: '-100%', duration: 0.45});

              headersTL.to('.home-section-content.active .icon',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 1,  y: '0%', scaleY: 1, duration: 0.75});
              headersTL.fromTo('.home-section-content.active h1.section-header .letter:nth-child(even)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-200%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1},
                '-=0.75');
              headersTL.fromTo('.home-section-content.active h1.section-header .letter:nth-child(odd)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '100%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1},
                '-=0.75');
              headersTL.fromTo('.home-section-content.active h2.section-sub-header',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-100%', autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', autoAlpha: 1, duration: 0.6},
                '-=0.2');

              headersTL.to('.home-section-content.active .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 1,   y: '0%', duration: 0.45});


              const bgTL = gsap.timeline({ease: 'none'});

              bgTL.to('.swiper-slide-active .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
              y: '0%', duration: 1, delay: 0.1});
              if ( $('.swiper-slide-next').length > 0 ){
                bgTL.to('.swiper-slide-next .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-50%', duration: 1, delay: 0.1}, '-=1');
              }
              bgTL.to('.swiper-slide-prev .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
              y: '50%', duration: 1, delay: 0.1}, '-=1');
            },


            slideNextTransitionEnd: (): void => {
              $('#magicMouseCursor').removeClass('is-scrolling-down');
              $('#magicMouseCursor').removeClass('is-scrolling-up');
              gsap.set( $('.nav-btns-container .prev-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              gsap.set( $('.nav-btns-container .next-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              $('.nav-btns-container .next-btn').removeClass('is-sliding-down');
              $('.nav-btns-container .prev-btn').removeClass('is-sliding-up');
              if(homeSwiper.activeIndex === homeSwiper.slides.length - 1){
                if ( homeSwiper.mousewheel.enabled ) {
                  homeSwiper.mousewheel.disable();
                }
              }
            },


            slidePrevTransitionStart: (): void => {
              $('.nav-btns-container .prev-btn').attr('style', '');
              $('.nav-btns-container .next-btn').attr('style', '');
              $('#magicMouseCursor').addClass('is-scrolling-up');
              gsap.set( $('.nav-btns-container .prev-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              gsap.set( $('.nav-btns-container .next-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              $('.nav-btns-container .prev-btn').addClass('is-sliding-up');
              const secContent = document.querySelectorAll('.home-section-content');
              secContent.forEach((el) => {
                $(el).removeClass('active');
              });
              $('.home-section-content').eq(homeSwiper.activeIndex).addClass('active');

              const headersTL = gsap.timeline({ease: 'none'});
              gsap.set('.home-section-content', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '-1'});
              gsap.set('.home-section-content.active', {z: 0.1, rotationZ: 0.01, force3D: true,
              zIndex: '1'});
              gsap.set('.home-section-content.active .icon',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 0,   y: '100%', scaleY: 2, duration: 0.45});
              gsap.set('.home-section-content.active .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 0, y: '100%', duration: 0.45});
              headersTL.to('.home-section-content.active .icon',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 1,  y: '0%', scaleY: 1, duration: 0.75});
              headersTL.fromTo('.home-section-content.active h1.section-header .letter:nth-child(odd)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '200%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1},
                '-=0.75');
              headersTL.fromTo('.home-section-content.active h1.section-header .letter:nth-child(even)',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '-100%', scaleY: 5, autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', scaleY: 1, autoAlpha: 1, duration: 0.55, stagger: 0.1},
                '-=0.75');
              headersTL.fromTo('.home-section-content.active h2.section-sub-header',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '100%', autoAlpha: 0},
                {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '0%', autoAlpha: 1, duration: 0.6},
                '-=0.2');
              headersTL.to('.home-section-content.active .cta',
                {z: 0.1, rotationZ: 0.01, force3D: true,
                autoAlpha: 1,  y: '0%', duration: 0.45});

              const bgTL = gsap.timeline({ease: 'none'});
              bgTL.to('.swiper-slide-active .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
              y: '0%', duration: 1, delay: 0.1});
              if ( $('.swiper-slide-prev').length > 0 ){
                bgTL.to('.swiper-slide-prev .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
                y: '50%', duration: 1, delay: 0.1}, '-=1');
              }
              bgTL.to('.swiper-slide-next .img-mask', {z: 0.1, rotationZ: 0.01, force3D: true,
              y: '-50%', duration: 1, delay: 0.1}, '-=1');
            },

            slidePrevTransitionEnd: (): void => {
              $('#magicMouseCursor').removeClass('is-scrolling-down');
              $('#magicMouseCursor').removeClass('is-scrolling-up');
              gsap.set( $('.nav-btns-container .prev-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              gsap.set( $('.nav-btns-container .next-btn'), {z: 0.1, rotationZ: 0.01, force3D: true});
              $('.nav-btns-container .prev-btn').removeClass('is-sliding-up');
              $('.nav-btns-container .next-btn').removeClass('is-sliding-down');
            }
          }
        });
      // }
    // });





    // Initializing Clients swiper carousel
    const clientsSwiper = new swiper('#clients-swiper', {
      // Optional parameters
      direction: 'horizontal',
      speed: 1500,
      grabCursor: true,
      autoHeight: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      loop: true,
      centeredSlides: false,
      slidesPerView: 5,
      slidesPerGroup: 1,
      spaceBetween: 50,
      watchOverflow: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 40
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 5,
          spaceBetween: 50
        }
      },
      preventInteractionOnTransition: false,
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      // },
      pagination: false,
      resistance: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: false,
      // navigation: {
      //   nextEl: '.next-btn',
      //   prevEl: '.prev-btn',
      // },
      navigation: false,
      scrollbar: false,
    });


    // Initialize Tilt.js

    const tiltOptions = {
      maxTilt:        2,
      perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
      easing:         'cubic-bezier(.03,.98,.52,.99)',    // Easing on enter/exit.
      scale:          1.02,      // 2 = 200%, 1.5 = 150%, etc..
      speed:          800,    // Speed of the enter/exit transition.
      transition:     false,   // Set a transition on enter/exit.
      disableAxis:    'x',   // What axis should be disabled. Can be X or Y.
      reset:          true,   // If the tilt effect has to be reset on exit.
      glare:          false,  // Enables glare effect
      maxGlare:       0.1       // From 0 - 1.
    };
    const bigTiltOptions = {
      maxTilt:        10,
      perspective:    1200,   // Transform perspective, the lower the more extreme the tilt gets.
      easing:         'cubic-bezier(.03,.98,.52,.99)',    // Easing on enter/exit.
      scale:          1,      // 2 = 200%, 1.5 = 150%, etc..
      speed:          800,    // Speed of the enter/exit transition.
      transition:     false,   // Set a transition on enter/exit.
      disableAxis:    'x',   // What axis should be disabled. Can be X or Y.
      reset:          true,   // If the tilt effect has to be reset on exit.
      glare:          false,  // Enables glare effect
      maxGlare:       0.1       // From 0 - 1.
    };
    // const tiltSection = $('.home-section').tilt(tiltOptions);
    // const tiltContent = $('.home-section-content .text-container').tilt(bigTiltOptions);
  }

  triggerGoDown(): void{
      gsap.to(window, {
        duration: 1,
        scrollTo:
        {
          y: '#clients-swiper',
          autoKill: true
        },
        ease: 'expo.out',
        delay: 0
      });
  }
}
