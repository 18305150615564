import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faMapMarkerAlt,
  faPhoneAlt,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

import * as ScrollMagic from 'scrollmagic';

import {magicMouse} from '../../../../assets/js/magic_mouse.js';

import Scrollbar, {ScrollbarPlugin} from 'smooth-scrollbar';
import { identifierModuleUrl } from '@angular/compiler';


declare var $;


@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss']
})
export class StrategyComponent implements OnInit, AfterViewInit {

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faMapMarkerAlt = faMapMarkerAlt;
  faPhoneAlt = faPhoneAlt;
  faPaperPlane = faPaperPlane;

  scrollbar;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event): void {
    // console.log(event);
  }

  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
      // y: '500',
      // scale: 5,
      duration: 2,
      autoAlpha: 0,
      opacity: 0,
      ease: 'power1.out',
    }, '-=2')
    .from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=1.55')
    .from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55');

    
    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);

    // Initializing swiper carousel
    const iconsSwiper = new swiper('#icons-swiper', {
      // Optional parameters
      direction: 'horizontal',
      speed: 1800,
      grabCursor: true,
      autoHeight: false,
      autoplay:  {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      preventInteractionOnTransition: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      resistance: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: false,
      // navigation: {
      //   nextEl: '.next-btn',
      //   prevEl: '.prev-btn',
      // },
      navigation: false,
      scrollbar: false,
    });



    // const headerTL = gsap.timeline();
    // headerTL.from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   // y: '500',
    //   // scale: 5,
    //   duration: 2,
    //   autoAlpha: 0,
    //   opacity: 0,
    //   ease: 'power1.out',
    // });
    // headerTL.from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=1.55');
    // headerTL.from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');




    $('.light-up-cursor').on('mouseenter', () => {
      $('#magicMouseCursor').addClass('clientHover');
    });
    $('.light-up-cursor').on('mouseleave', () => {
      $('#magicMouseCursor').removeClass('clientHover');
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // ROOK LINE PATH ANIMATION
      if ($('.showcases').length > 0){
        const rookPath = document.querySelector('#rook-line');
        const rookPathLength = (rookPath as SVGPathElement).getTotalLength();
        const _rookPath = $('#rook-line');
        _rookPath.css('strokeDasharray', 0);
        _rookPath.css('strokeDashoffset', rookPathLength);
        window.addEventListener('scroll', (e) => {
          const distanceFromTop = $('#rook-line').offset().top - $(window).scrollTop();
          const rookScrollPercentage = 1 - distanceFromTop / $(window).height();
          const rookDrawLength = rookPathLength * rookScrollPercentage;
          _rookPath.css('strokeDashoffset', rookPathLength - rookDrawLength);
          if (rookScrollPercentage >= 0.99) {
            _rookPath.css('strokeDasharray', rookDrawLength * 1);
          } else {
            _rookPath.css('strokeDasharray', rookPathLength * 1);
          }
        }, true);
  
  
        // KNIGHT LINE PATH ANIMATION
        const knightPath = document.querySelector('#knight-line');
        const knightPathLength = (knightPath as SVGPathElement).getTotalLength();
        const _knightPath = $('#knight-line');
        _knightPath.css('strokeDasharray', 0);
        _knightPath.css('strokeDashoffset', knightPathLength);
        window.addEventListener('scroll', (e) => {
          const distanceFromTop = $('#knight-line').offset().top - $(window).scrollTop();
          const knightScrollPercentage = 1 - distanceFromTop / $(window).height();
          const knightDrawLength = knightPathLength * knightScrollPercentage;
          _knightPath.css('strokeDashoffset', knightPathLength - knightDrawLength);
          if (knightScrollPercentage >= 0.99) {
            _knightPath.css('strokeDasharray', knightDrawLength * 1);
          } else {
            _knightPath.css('strokeDasharray', knightPathLength * 1);
          }
        }, true);
  
        // BISHOPS LINE PATH ANIMATION
        const bishopsPath = document.querySelector('#bishops-line');
        const bishopsPathLength = (bishopsPath as SVGPathElement).getTotalLength();
        const _bishopsPath = $('#bishops-line');
        _bishopsPath.css('strokeDasharray', 0);
        _bishopsPath.css('strokeDashoffset', bishopsPathLength);
        window.addEventListener('scroll', (e) => {
          const distanceFromTop = $('#bishops-line').offset().top - $(window).scrollTop();
          const bishopsScrollPercentage = 1 - distanceFromTop / $(window).height();
          const bishopsDrawLength = bishopsPathLength * bishopsScrollPercentage;
          _bishopsPath.css('strokeDashoffset', bishopsPathLength - bishopsDrawLength);
          if (bishopsScrollPercentage >= 0.99) {
            _bishopsPath.css('strokeDasharray', bishopsDrawLength * 1);
          } else {
            _bishopsPath.css('strokeDasharray', bishopsPathLength * 1);
          }
        }, true);
  
        // QUEEN LINE PATH ANIMATION
        const queenPath = document.querySelector('#queen-line');
        const queenPathLength = (queenPath as SVGPathElement).getTotalLength();
        const _queenPath = $('#queen-line');
        _queenPath.css('strokeDasharray', 0);
        _queenPath.css('strokeDashoffset', queenPathLength);
        window.addEventListener('scroll', (e) => {
          const distanceFromTop = $('#queen-line').offset().top - $(window).scrollTop();
          const queenScrollPercentage = 1 - distanceFromTop / $(window).height();
          const queenDrawLength = queenPathLength * queenScrollPercentage;
          _queenPath.css('strokeDashoffset', queenPathLength - queenDrawLength);
          if (queenScrollPercentage >= 0.99) {
            _queenPath.css('strokeDasharray', queenDrawLength * 1);
          } else {
            _queenPath.css('strokeDasharray', queenPathLength * 1);
          }
        }, true);
      }
      



      //////// LEFT SIDED
      //////// left BACKGROUND animations
      let bgLeft = gsap.utils.toArray('.bg-left');

      bgLeft.forEach( (currentValue, index, arr) => {
        gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 200,
          ease: 'none',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 2,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
      });

      //////// left LINE animations
      let lineLeft = gsap.utils.toArray('.line-left');

      // lineLeft.forEach( (currentValue, index, arr) => {
      //   gsap.set($(`#${$(currentValue).attr('id')}`) , { rotationX: '30deg' });
      //   gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, rotationX: '30deg', force3D: true,
      //     y: 100,
      //     autoAlpha: 0,
      //     ease: 'none',
      //     scrollTrigger: {
      //       trigger: $(`#${$(currentValue).attr('id')}`).parent(),
      //       scrub: 1,
      //       start: 'top center',
      //       end: 'bottom center'
      //     }
      //   });
      // });
      //////// left TEXT animations
      let textLeft = gsap.utils.toArray('.text-container-left');

      textLeft.forEach( (currentValue, index, arr) => {
        gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 500,
          autoAlpha: 0,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 3,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
      });



      //////// RIGHT SIDED
      //////// right BACKGROUND animations
      let bgRight = gsap.utils.toArray('.bg-right');

      bgRight.forEach( (currentValue, index, arr) => {
        gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 200,
          ease: 'none',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 2,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
      });

      //////// right LINE animations
      let lineRight = gsap.utils.toArray('.line-right');

      // lineRight.forEach( (currentValue, index, arr) => {
      //   gsap.set($(`#${$(currentValue).attr('id')}`) , { rotationX: '-30deg' });
      //   gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, rotationX: '-30deg', force3D: true,
      //     y: 100,
      //     autoAlpha: 0,
      //     ease: 'none',
      //     scrollTrigger: {
      //       trigger: $(`#${$(currentValue).attr('id')}`).parent(),
      //       scrub: 1,
      //       start: 'top center',
      //       end: 'bottom center'
      //     }
      //   });
      // });
      //////// right TEXT animations
      let textRight = gsap.utils.toArray('.text-container-right');

      textRight.forEach( (currentValue, index, arr) => {
        gsap.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 500,
          autoAlpha: 0,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 3,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
      });

      gsap.from( '#small-pawns' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: 200,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.showcases',
          scrub: 2,
          start: 'top center',
          end: 'bottom center'
        }
      });

      gsap.from( '#medium-pawns' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: 300,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.showcases',
          scrub: 3,
          start: 'top center',
          end: 'bottom center'
        }
      });

      gsap.from( '#big-pawns' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: 400,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.showcases',
          scrub: 4,
          start: 'top center',
          end: 'bottom center'
        }
      });

      }, 0);


  }


}
