<!-- <div class="scroll-container"> -->
  <main id="get-quote-container">
    <div class="container d-flex flex-column justify-content-center align-items-center">
        <div class="header-wrapper">
            <h1>GET QUOTE</h1>
        <p>Please fill in the below information accurately with details so we can get back to you as soon as possible</p>

        </div>
    </div>
    <div>
        <div class="get-quote-form-wrapper">
            <app-get-quote-form [formHeader]="getQuoteFormHeader"></app-get-quote-form>
        </div>


    </div>
    <div class="next-container">
        <div class="next-wrapper">
            <p>Next</p>
            <h1>
                <a routerLink="/strategy" class="magic-hover magic-hover__square">
                    STRATEGY
                </a>
            </h1>
        </div>
    </div>
</main>
<!-- </div> -->
