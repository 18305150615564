<app-header></app-header>
<div class="viewport">
    <!-- <div class="scroll-container"> -->
        <!-- <div [@routeAnimations]="prepareRoute(outlet)"> -->
            <router-outlet></router-outlet>
        <!-- </div> -->
    <!-- </div> -->
    <div class="loading-container"></div>
</div>
<app-back-to-top></app-back-to-top>
<app-social></app-social>
<app-footer></app-footer>



