<!-- <div class="scroll-container"> -->
    <main id="our-clients-container">
        <div class="container d-flex flex-column justify-content-center align-items-center">
            <div class="header-wrapper">
                <h1>OUR CLIENTS</h1>
                <h2>Clients that can't live without our services</h2>
                <p>We are just a group of normal people who live , walk and breath like you... We are just a bit more creative , definitely funnier more insightful, relatable and we know how to win and make others win.. But what we lack in humbleness we make up for in our 360 marketing services and marketing strategies with Full VR services</p>
            </div>
            <div class="clients-wrapper">
                <ul class="clients-grid-wrapper">
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Ahmad-Tea.png" title="Ahmed Tea" alt="Ahmed Tea" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Allianz.png" title="Allianz" alt="Allianz" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/ecowest.png" title="EcoWest" alt="EcoWest" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/El-Abd.png" title="El-Abd" alt="El-Abd" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/future.png" title="Future" alt="Future" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Garnier.png" title="Garnier" alt="Garnier" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/hala.png" title="Hala" alt="Hala" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Hero-Spreed.png" title="Hero" alt="Hero" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/HPD.png" title="HPD" alt="HPD" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/hydeout.png" title="Hydeout" alt="Hydeout" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/krestase.png" title="Krestase" alt="Krestase" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Loreal.png" title="Loreal" alt="Loreal" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Maghrabi.png" title="Maghrabi" alt="Maghrabi" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/Miele.png" title="Miele" alt="Miele" />
                  </li>
                  <li class="client-container light-up-cursor">
                    <img src="../../../assets/img/clients/new/vitrac.png" title="vitrac" alt="vitrac" />
                  </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/americana.png" title="Americana" alt="Americana" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Cheetos.png" title="Cheetos" alt="Cheetos" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Costa.png" title="Costa Coffee" alt="Costa Coffee" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Disny.png" title="Disney" alt="Disney" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Dodge.png" title="Dodge" alt="Dodge" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Egyptionsteel.png" title="Egyptian Steel" alt="Egyptian Steel" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/emmar.png" title="Emmar" alt="Emmar" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Fusin.png" title="Fusin" alt="Fusin" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Grand-Cafe.png" title="Grand Cafe" alt="Grand Cafe" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/greenland.png" title="GreenLand" alt="GreenLand" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/hardess.png" title="Hardees" alt="Hardees" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Hinez.png" title="Hienz" alt="Hienz" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/KFC.png" title="KFC" alt="KFC" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/KitKat.png" title="KitKat" alt="KitKat" />
                    </li>
                    <!-- <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Knors.png" title="Knors" alt="Knors" />
                    </li> -->
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Lego.png" title="Lego" alt="Lego" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Maggi.png" title="Maggi" alt="Maggi" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/MG.png" title="MG" alt="MG" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Morshedy.png" title="Morshedy" alt="Morshedy" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/MSD.png" title="MSD" alt="MSD" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Orascom.png" title="Orascom" alt="Orascom" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Plaza.png" title="Plaza" alt="Plaza" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/QS.png" title="QS" alt="QS" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/univadis.png" title="univadis" alt="univadis" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Youm7.png" title="Youm7" alt="Youm7" />
                    </li>
                    <li class="client-container light-up-cursor">
                        <img src="../../../assets/img/clients/Zeego.png" title="Zeego" alt="Zeego" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="next-container">
            <div class="next-wrapper">
                <p>Let's Talk</p>
                <h1>
                    <a routerLink="/contact-us" class="magic-hover magic-hover__square">
                        CONTACT US
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->





