import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faArrowUp,
  faChevronUp,
  faPaperPlane,
  faPaperclip,
  faUser,
  faAt,
  faPhone,
  faComment,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

// Email js
import emailjs from '@emailjs/browser';

// Sweet Alert
import Swal from 'sweetalert2'

import { magicMouse } from '../../../assets/js/magic_mouse.js';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
// import { Email } from '../../../assets/js/smtp 2.js';

declare var $;


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, AfterViewInit, OnDestroy {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faArrowUp = faArrowUp;
  faChevronUp = faChevronUp;
  faPaperPlane = faPaperPlane;
  faPaperclip = faPaperclip;
  faCheck = faCheck;
  faTimes = faTimes;
  @ViewChild('careers_form', { static: false }) careers_form: ElementRef

  userName: string = '';
  userEmail: string = '';
  userPhone: string = '';
  userCV: object = {};
  userCVuri;
  cvBlob: Blob;
  userCVName: string = '';
  @ViewChild('userCVRef') userCVRef: ElementRef;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;
  isSent: boolean = false;

  errorMessage: string;

  careersForm$: Subscription;

  careersForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^[+]{0,1}[\d]{9,13}$/)]),
    // attachment: new FormControl(''),
    cv: new FormControl(''),
  });

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
      .to('.loading-container', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        autoAlpha: 1,
        duration: 1,
        ease: 'Power1.easeOut',
      })
      .to('.loading-container', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        autoAlpha: 0,
        duration: 2,
        ease: 'Power1.easeOut',
      })
      .from('main', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        autoAlpha: 0,
        duration: 1,
        ease: 'Power3.easeOut',
      }, '-=2')
      .to('.loading-container', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        y: '-100%',
        autoAlpha: 0,
        duration: 0.1,
        ease: 'Power2.easeOut',
      })

      .from('.header-wrapper', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        // scale: 4,
        autoAlpha: 0,
        duration: 2,
        ease: 'Power3.easeOut',
      }, '-=2')
      .from('.objective-wrapper', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        duration: 1.2,
        ease: 'Power3.easeOut',
      }, '-=1.55')
      .from('.careers-form-wrapper', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        y: -50,
        autoAlpha: 0,
        ease: 'Power3.easeOut',
        duration: 1.2,
      });

    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);


    this.careersForm$ = this.careersForm.valueChanges.subscribe(formData => {
      // console.log(formData);
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      //// on scroll

      gsap.from('.next-container', {
        z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.next-container',
          scrub: 3,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });

    }, 0);

  }
  ngOnDestroy(): void {
    this.careersForm$.unsubscribe();
  }


  careersMail(): void {
    if (this.careersForm.valid && this.userCVuri && !this.hasError) {
      this.userName = this.careersForm.value.name;
      this.userEmail = this.careersForm.value.email;
      this.userPhone = this.careersForm.value.phone;
      this.userCV = this.careersForm.value.attachment;
      this.isPending = false;
      this.hasError = false;
      this.isLoading = true;

      this.dataURItoBlob(this.userCVuri.changingThisBreaksApplicationSecurity).then(
        cvBlob => {
          this.cvBlob = cvBlob;
          const formData = new FormData(document.forms.namedItem('careersForm'));
          formData.append('cv', this.cvBlob, this.userCVName);
          formData.delete('attachment');

          // Send email
          // emailjs.send("service_s90ajzh", "template_0brk7bw", {
          //   to_name: 'Admin',
          //   from_name: this.careersForm.value.name,
          //   from_email: this.careersForm.value.email,
          //   from_phone: this.careersForm.value.phone,
          //   attachment: this.careersForm.value.cv,
          // }, 'ibbFoouQH3_XaicE7');
          // alert('Sent');
          // this.careersForm.reset();

          // Save to google sheets
          // const careersFormSubmitURL = "https://script.google.com/macros/s/AKfycbyb_VcTLqD0YHgUyRYEZNJZLClidG6QD8inR6t5F0z8HrnjWjbUTX7wE37UQJfl6aqyNA/exec";

          // My form account
          // const careersFormSubmitURL = "https://getform.io/f/3b86745b-cb5e-4573-adde-63186183ce9f";

          // HR form account
          const careersFormSubmitURL = "https://getform.io/f/8f6e2af5-8e80-4272-8479-dee87c42aa84";

          fetch(careersFormSubmitURL, {
            method: "POST",
            body: formData,
            mode: 'no-cors',
          })
            .then((resp) => {
              Swal.fire(
                'Thank you!',
                'Your CV has been sent to us. <br>We will contact you as soon as possible.',
                'success'
              );
              this.careersForm.reset();

              this.userCVName = '';
              this.isLoading = false;
              this.hasError = false;
              this.isPending = true;
              this.isSent = true;

              setTimeout(() => {
                this.isSent = false;
              }, 10000);
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
              this.isPending = true;
              this.hasError = true;
              this.isSent = false;
              setTimeout(() => {
                this.hasError = false;
              }, 10000);
            });
        }).catch(
          reason => {
            this.isLoading = false;
            this.isPending = true;
            this.hasError = true;
            this.isSent = false;
            setTimeout(() => {
              this.hasError = false;
            }, 10000);
            this.errorMessage = reason;
          }
        );
    }
  }


  async dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return await blob;

  }

  fileChangeEvent(fileInput: any): void {

    if (fileInput.target.files && fileInput.target.files[0]) {

      this.userCVName = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.userCV = file;
        this.userCVName += file.name;
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.userCVuri = this.sanitizer.bypassSecurityTrustUrl(e.target.result);
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      this.userCVRef.nativeElement.value = '';
    } else {
      this.userCVName = '';
    }
  }

}


