<!-- <div class="scroll-container"> -->
    <main id="innovation-container">
        <div class="service-hero-container">
            <div class="service-hero-wrapper d-flex flex-column justify-content-between align-items-center">
                <div class="header-wrapper d-flex flex-column justify-content-center align-items-center">
                    <div class="text-wrapper container">
                        <div class="sectionLogoContainer">
                            <img src="../../../../assets/img/innovation/innovationLogo.svg" />
                        </div>
                        <h1>INNOVATION</h1>
                        <p class="sub-header">THE COURAGE TO EXPLORE</p>
                        <p class="text">
                          Let us take you and your potential clients into a virtual trip you will never forget with our VR technology
                        </p>
                    </div>
                    <div class="icons-Wrapper container">
                        <div id="icons-swiper" class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/innovation/innovationServiceIcon1.png"/>
                                    </div>
                                    <p>Virtual Reality</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/innovation/innovationServiceIcon2.png"/>
                                    </div>
                                    <p>Hologram Solutions</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/innovation/innovationServiceIcon3.png"/>
                                    </div>
                                    <p>Projection Mapping</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/innovation/innovationServiceIcon4.png"/>
                                    </div>
                                    <p>Interactive Photo Booth</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/innovation/innovationServiceIcon5.png"/>
                                    </div>
                                    <p>Virtual Mannequin</p>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>

                            <!-- <div id="icons-nav-btns-container" class="nav-btns-container">
                                <button class="prev-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                                </button>
                                <button class="next-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronRight"></fa-icon>
                                </button>
                            </div> -->

                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div id="download-btn-container">
          <a href="https://drive.google.com/file/d/1H499PX3nzS6axp-aUZ_oAvfer8bGdrUt/view" target="_blank" class="magic-hover magic-hover__square">
            DOWNLOAD OUR COMPANY PROFILE <fa-icon *ngIf="isPending" [icon]="faDownload"></fa-icon><mat-spinner *ngIf="isLoading" color="primary"></mat-spinner>
          </a>
        </div>
        <div class="next-container">
            <div class="next-wrapper">
                <!-- <p>Next</p> -->
                <h1>
                    <a routerLink="/get-quote" class="magic-hover magic-hover__square">
                        GET QUOTE
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->
