<!-- <div class="scroll-container"> -->
    <!-- $0qmOv29 -->
    <main id="home-container">
        <!-- Slider main container -->
        <div id="home-swiper" class="swiper-container">
            <!-- Additional required wrapper -->
            <div class="content-container">
                <div id="welcome-section-content" class="home-section-content">
                    <div class="text-container">
                        <!-- <img class="icon" src="../../../assets/img/logos/logo-white.svg"/> -->
                        <!-- <h2 class="welcome-sub-header">THE</h2> -->
                        <h2 class="section-sub-header mb-3">ONE STOP SHOP</h2>

                        <h1 class="section-header">
                            FIFTH DIMENSION
                            <!-- <span class="text-wrapper">
                                <span class="line line1"></span>
                                <span class="letters letters-left">FIFTH</span>
                                <span class="letters ampersand"></span>
                                <span class="letters letters-right">DIMENSION</span>
                                <span class="line line2"></span>
                            </span> -->
                        </h1>
                        <a class="cta" mat-raised-button color="basic" routerLink="/who-we-are">
                            ABOUT US
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>

                    </div>
                </div>
                <div id="strategy-section-content" class="home-section-content">
                    <div class="text-container">
                        <img class="icon" src="assets/img/icons/strategyLogo.svg"/>
                        <h1 class="section-header">STRATEGY</h1>
                        <h2 class="section-sub-header">THE NEED TO EVOLVE</h2>
                        <a class="cta" mat-raised-button color="basic" routerLink="/strategy">
                            EXPLORE
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>
                    </div>

                </div>
                <div id="creative-section-content" class="home-section-content">
                    <div class="text-container">
                        <img class="icon" src="assets/img/icons/creativeLogo.svg"/>
                        <h1 class="section-header">CREATIVE</h1>
                        <h2 class="section-sub-header">THE POWER TO IMAGINE</h2>
                        <a class="cta" mat-raised-button color="basic" routerLink="/creative">
                            EXPLORE
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>
                    </div>

                </div>
                <div id="production-section-content" class="home-section-content">
                    <div class="text-container">
                        <img class="icon" src="assets/img/icons/productionLogo.svg"/>
                        <h1 class="section-header">DEVELOPMENT</h1>
                        <h2 class="section-sub-header">THE WILL TO ACHIEVE</h2>
                        <a class="cta" mat-raised-button color="basic" routerLink="/development">
                            EXPLORE
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>
                    </div>

                </div>
                <div id="reach-section-content" class="home-section-content">
                    <div class="text-container">
                        <img class="icon" src="assets/img/icons/reachLogo.svg"/>
                        <h1 class="section-header">SOCIAL MEDIA</h1>
                        <h2 class="section-sub-header">THE ABILITY TO ATTRACT</h2>
                        <a class="cta" mat-raised-button color="basic" routerLink="/social-media">
                            EXPLORE
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>
                    </div>

                </div>
                <div id="innovation-section-content" class="home-section-content">
                    <div class="text-container">
                        <img class="icon" src="assets/img/icons/innovationLogo.svg"/>
                        <h1 class="section-header">INNOVATION</h1>
                        <h2 class="section-sub-header">THE COURAGE TO EXPLORE</h2>
                        <a class="cta" mat-raised-button color="basic" routerLink="/innovation">
                            EXPLORE
                            <!-- <fa-icon [icon]="faChevronRight"></fa-icon> -->
                        </a>
                    </div>

                </div>
            </div>
            <div class="swiper-wrapper">
                <!-- Slides -->
                <section id="welcome-section" class="home-section swiper-slide" data-title="FIFTH DIMENSION" data-subtitle="WELCOME TO THE" data-slogan="ONE STOP SHOP" data-number="01">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>
                </section>
                <section id="strategy-section" class="home-section swiper-slide" data-title="STRATEGY" data-subtitle="THE NEED TO EVOLVE" data-number="02">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>

                </section>
                <section id="creative-section" class="home-section swiper-slide" data-title="CREATIVE" data-subtitle="THE POWER TO IMAGINE" data-number="03">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>
                </section>
                <section id="production-section" class="home-section swiper-slide" data-title="PRODUCTION" data-subtitle="THE WILL TO ACHIEVE" data-number="04">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>
                </section>
                <section id="reach-section" class="home-section swiper-slide" data-title="REACH" data-subtitle="THE ABILITY TO ATTRACT" data-number="05">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>
                </section>
                <section id="innovation-section" class="home-section swiper-slide" data-title="INNOVATION"  data-subtitle="THE COURAGE TO EXPLORE" data-number="06">
                    <div class="img-mask">
                        <div class="section-image"></div>
                    </div>
                </section>
                <!-- <section id="innovation-section" class="home-section contact-section swiper-slide" data-title="INNOVATION"  data-subtitle="THE COURAGE TO EXPLORE" data-number="06">



                    <app-contact-form [formHeader]="contactFormHeader"></app-contact-form>
                </section> -->
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>

            <!-- If we need navigation buttons -->
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->
            <div id="home-nav-btns-container" class="nav-btns-container">


                <div class="btns-wrapper">
                    <button class="nav-btn prev-btn magic-hover magic-hover__square" type="button">
                        <fa-icon [icon]="faChevronUp"></fa-icon>
                    </button>
                    <button class="nav-btn next-btn magic-hover magic-hover__square" type="button">
                        <fa-icon [icon]="faChevronDown"></fa-icon>
                    </button>
                </div>


                <div class="labels-container">
                    <!-- <button mat-flat-button color="basic" class="goDown-btn magic-hover magic-hover__square" type="button" (click)="triggerGoDown()">
                        KEEP IN TOUCH
                        <fa-icon [icon]="faComment"></fa-icon>
                    </button> -->
                    <button mat-flat-button routerLink="/get-quote" color="basic" class="goDown-btn magic-hover magic-hover__square" type="button">
                      GET QUOTE
                      <fa-icon [icon]="faComment"></fa-icon>
                    </button>
                    <!-- <div class="label-wrapper">
                        <span class="next-label"><img src="../../../assets/img/icons/strategyLogo.svg" /></span>
                        <span class="next-title">STRATEGY</span>
                    </div>
                    <div class="label-wrapper">
                        <span class="next-label"><img src="../../../assets/img/icons/creativeLogo.svg" /></span>
                        <span class="next-title">CREATIVE</span>
                    </div>
                    <div class="label-wrapper">
                        <span class="next-label"><img src="../../../assets/img/icons/productionLogo.svg" /></span>
                        <span class="next-title">PRODUCTION</span>
                    </div>
                    <div class="label-wrapper">
                        <span class="next-label"><img src="../../../assets/img/icons/reachLogo.svg" /></span>
                        <span class="next-title">REACH</span>
                    </div>
                    <div class="label-wrapper">
                        <span class="next-label"><img src="../../../assets/img/icons/innovationLogo.svg" /></span>
                        <span class="next-title">INNOVATION</span>
                    </div> -->

                </div>

            </div>

            <!-- If we need scrollbar -->
            <div class="swiper-scrollbar"></div>
            <!-- <app-back-to-top></app-back-to-top> -->
        </div>
        <div id="clients-swiper" class="clients-swiper-container swiper-container">
            <div class="swiper-wrapper">
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Ahmad-Tea.png" title="Ahmed Tea" alt="Ahmed Tea" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Allianz.png" title="Allianz" alt="Allianz" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/ecowest.png" title="EcoWest" alt="EcoWest" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/El-Abd.png" title="El-Abd" alt="El-Abd" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/future.png" title="Future" alt="Future" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Garnier.png" title="Garnier" alt="Garnier" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/hala.png" title="Hala" alt="Hala" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Hero-Spreed.png" title="Hero" alt="Hero" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/HPD.png" title="HPD" alt="HPD" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/hydeout.png" title="Hydeout" alt="Hydeout" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/krestase.png" title="Krestase" alt="Krestase" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Loreal.png" title="Loreal" alt="Loreal" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Maghrabi.png" title="Maghrabi" alt="Maghrabi" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/Miele.png" title="Miele" alt="Miele" />
              </li>
              <li class="client-slide swiper-slide">
                <img src="../../../assets/img/clients/new/vitrac.png" title="vitrac" alt="vitrac" />
              </li>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/americana.png" title="Americana" alt="Americana">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Cheetos.png" title="Cheetos" alt="Cheetos">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Costa.png" title="Costa Coffee" alt="Costa Coffee">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Disny.png" title="Disney" alt="Disney">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Dodge.png" title="Dodge" alt="Dodge">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Egyptionsteel.png" title="Egyptian Steel" alt="Egyptian Steel">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/emmar.png" title="Emmar" alt="Emmar">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Fusin.png" title="Fusin" alt="Fusin">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Grand-Cafe.png" title="Grand Cafe" alt="Grand Cafe">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/greenland.png" title="GreenLand" alt="GreenLand">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/hardess.png" title="Hardees" alt="Hardees">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Hinez.png" title="Hienz" alt="Hienz">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/KFC.png" title="KFC" alt="KFC">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/KitKat.png" title="KitKat" alt="KitKat">
                </div>
                <!-- <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Knors.png" title="Knors" alt="Knors">
                </div> -->
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Lego.png" title="Lego" alt="Lego">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Maggi.png" title="Maggi" alt="Maggi">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/MG.png" title="MG" alt="MG">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Morshedy.png" title="Morshedy" alt="Morshedy">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/MSD.png" title="MSD" alt="MSD">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Orascom.png" title="Orascom" alt="Orascom">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Plaza.png" title="Plaza" alt="Plaza">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/QS.png" title="QS" alt="QS">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/univadis.png" title="Univadis" alt="Univadis">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Youm7.png" title="Youm7" alt="Youm7">
                </div>
                <div class="client-slide swiper-slide">
                    <img src="../../../assets/img/clients/Zeego.png" title="Zeego" alt="Zeego">
                </div>
            </div>
        </div>


        <app-contact-form [formHeader]="contactFormHeader"></app-contact-form>

        <!-- <div class="next-container">
            <div class="next-wrapper">
                <p>Next</p>
                <h1>
                    <a routerLink="/who-we-are" class="magic-hover magic-hover__square">
                        WHO WE ARE
                    </a>
                </h1>
            </div>
        </div>   -->
    </main>
<!-- </div> -->

