import { Component, OnInit, HostListener } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';


import {magicMouse} from '../../../../assets/js/magic_mouse.js';


declare var $;

@Component({
  selector: 'app-innovation',
  templateUrl: './innovation.component.html',
  styleUrls: ['./innovation.component.scss']
})
export class InnovationComponent implements OnInit {

  faDownload = faDownload;

  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
      // y: '500',
      // scale: 5,
      duration: 2,
      autoAlpha: 0,
      opacity: 0,
      ease: 'power1.out',
    }, '-=2')
    .from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=1.55')
    .from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55');


    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);


    // Initializing swiper carousel
    const iconsSwiper = new swiper('#icons-swiper', {
      // Optional parameters
      direction: 'horizontal',
      speed: 1800,
      grabCursor: true,
      autoHeight: false,
      autoplay:  {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      preventInteractionOnTransition: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      resistance: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: false,
      // navigation: {
      //   nextEl: '.next-btn',
      //   prevEl: '.prev-btn',
      // },
      navigation: false,
      scrollbar: false,
    });



    // const headerTL = gsap.timeline();
    // headerTL.from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   // y: '500',
    //   // scale: 5,
    //   duration: 2,
    //   autoAlpha: 0,
    //   opacity: 0,
    //   ease: 'power1.out',
    // });
    // headerTL.from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=1.55');
    // headerTL.from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');

    $('.light-up-cursor').on('mouseenter', () => {
      $('#magicMouseCursor').addClass('clientHover');
    });
    $('.light-up-cursor').on('mouseleave', () => {
      $('#magicMouseCursor').removeClass('clientHover');
    });

  }


}
