<!-- <div class="scroll-container"> -->
  <main id="notFound-container">
    <div class="service-hero-container">
        <div class="service-hero-wrapper d-flex flex-column justify-content-between align-items-center">
            <div class="header-wrapper d-flex flex-column justify-content-center align-items-center">
                <div class="text-wrapper container">
                    <div class="sectionLogoContainer">
                        <img src="../../../assets/img/logos/logo-white.svg" />
                    </div>
                    <h1>PAGE NOT FOUND</h1>
                    <p class="sub-header">We are sorry, it seems like the page you are looking for is not found.</p>
                    <!-- <p class="text">
                      Let us tell you a story, in a cold winter night shining on the calm soothing fields of Chicago Illinois sits Philip Kotler the founder of modern marketing , modern marketing that is built on great story telling..
                      <br>
                      <strong>Arent we just great at that?!</strong>
                    </p> -->
                </div>


            </div>
        </div>
    </div>

    <!-- <div id="download-btn-container">
      <a routerLink="/home" class="magic-hover magic-hover__square">
        <fa-icon [icon]="faChevronLeft"></fa-icon> HOME
      </a>
    </div> -->

    <div class="next-container">
        <div class="next-wrapper">
            <p>Back to Home</p>
            <h1>
                <a routerLink="/home" class="magic-hover magic-hover__square">
                    <fa-icon [icon]="faChevronLeft"></fa-icon> HOME
                </a>
            </h1>
        </div>
    </div>
</main>
<!-- </div> -->
