<!-- <div class="scroll-container"> -->
  <main id="get-quote-thankyou-container">
    <div class="container d-flex flex-column justify-content-center align-items-center">
        <div class="header-wrapper">
            <p class="icon"><fa-icon [icon]="faCheckCircle"></fa-icon></p>
            <h1>THANK YOU</h1>
            <p>Thanks for your interest in getting quotation, we will contact you as soon as possible.</p>
        </div>
    </div>
    <br>
    <br>
    <div id="download-btn-container">
      <a href="https://drive.google.com/file/d/1-GwN_CaT_uPLOK31Y22nKzIRIz20Pvlh/view?usp=sharing" target="_blank" class="magic-hover magic-hover__square">
        DOWNLOAD OUR COMPANY PROFILE <fa-icon *ngIf="isPending" [icon]="faDownload"></fa-icon><mat-spinner *ngIf="isLoading" color="primary"></mat-spinner>
      </a>
    </div>


    <div class="next-container">
        <div class="next-wrapper">
          <p><fa-icon [icon]="faHome"></fa-icon></p>
            <h1>
                <a routerLink="/home" class="magic-hover magic-hover__square">
                  <fa-icon [icon]="faChevronLeft"></fa-icon> BACK TO HOME
                </a>
            </h1>
        </div>
    </div>
</main>
<!-- </div> -->
