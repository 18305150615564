import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faMapMarkerAlt,
  faPhoneAlt,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

import {magicMouse} from '../../../assets/js/magic_mouse.js';


declare var $;

@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss']
})
export class GetQuoteComponent implements OnInit, AfterViewInit {

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faMapMarkerAlt = faMapMarkerAlt;
  faPhoneAlt = faPhoneAlt;
  faPaperPlane = faPaperPlane;

  userName = '';
  userEmail = '';
  userPhone = '';
  userMessage = '';


  getQuoteFormHeader: string = `Let's Talk`;


  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.header-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        duration: 2,
        ease: 'Power3.easeOut',
    }, '-=2')
    .from( '.get-quote-form-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -50,
        autoAlpha: 0,
        ease: 'Power3.easeOut',
        duration: 1.2,
    }, '-=1.55');


    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);

  }


  ngAfterViewInit(): void {

    setTimeout( () => {



      // on scroll
      // gsap.from( '.get-quote-form-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -50,
      //   autoAlpha: 0,
      //   ease: 'power1.out',
      //   scrollTrigger: {
      //     trigger: '.get-quote-form-wrapper',
      //     scrub: 2,
      //     start: 'top bottom',
      //     end: 'bottom bottom'
      //   }
      // });

      gsap.from( '.next-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.next-container',
          scrub: 3,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });
    }, 0);
  }

}





