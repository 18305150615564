import { Component, OnInit, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faPlay,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
// import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack';
// import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
// import { Draggable } from 'gsap/Draggable';
// import { EaselPlugin } from 'gsap/EaselPlugin';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
// import { PixiPlugin } from 'gsap/PixiPlugin';
// import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { CustomEase } from 'gsap/CustomEase';
// import { CustomBounce } from 'gsap/CustomBounce';
// import { CustomWiggle } from 'gsap/CustomWiggle';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';


import {magicMouse} from '../../../../assets/js/magic_mouse.js';

import * as Plyr from 'plyr';


declare var $;

@Component({
  selector: 'app-creative',
  templateUrl: './creative.component.html',
  styleUrls: ['./creative.component.scss']
})
export class CreativeComponent implements OnInit, AfterViewInit, OnDestroy {

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faPlay = faPlay;
  faDownload = faDownload;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      // TextPlugin,
      // CSSRulePlugin,
      // ExpoScaleEase,
      // RoughEase,
      // SlowMo,
      // EaselPlugin,
      // PixiPlugin,
      // MotionPathPlugin
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);


    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
      // y: '500',
      // scale: 5,
      duration: 2,
      autoAlpha: 0,
      opacity: 0,
      ease: 'power1.out',
    }, '-=2')
    .from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=1.55')
    .from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '100',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55')
    .from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-50',
      duration: 1.2,
      autoAlpha: 0,
      ease: 'back.out(1.7)',
    }, '-=0.55');

    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);


    // Initializing swiper carousel
    const iconsSwiper = new swiper('#icons-swiper', {
      // Optional parameters
      direction: 'horizontal',
      speed: 1800,
      grabCursor: true,
      autoHeight: false,
      autoplay:  {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 960px
        960: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      preventInteractionOnTransition: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      resistance: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: false,
      // navigation: {
      //   nextEl: '.next-btn',
      //   prevEl: '.prev-btn',
      // },
      navigation: false,
      scrollbar: false,
    });



    // const headerTL = gsap.timeline();
    // headerTL.from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   // y: '500',
    //   // scale: 5,
    //   duration: 2,
    //   autoAlpha: 0,
    //   opacity: 0,
    //   ease: 'power1.out',
    // });
    // headerTL.from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=1.55');
    // headerTL.from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');
    // headerTL.from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');



    $('.light-up-cursor').on('mouseenter', () => {
      $('#magicMouseCursor').addClass('clientHover');
    });
    $('.light-up-cursor').on('mouseleave', () => {
      $('#magicMouseCursor').removeClass('clientHover');
    });

    const dangoPlyr = new Plyr('#dango-plyr');

  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      let productContainer = gsap.utils.toArray('.product-container');
      productContainer.forEach( (currentValue, index, arr) => {
        let productTL = gsap.timeline();
        productTL.from( $(`#${$(currentValue).attr('id')}`) , {z: 0.1, rotationZ: 0.01, force3D: true,
          // y: 0,
          // scale: 1,
          autoAlpha: 0,
          duration: 1,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });

      });



      //////// RIGHT SIDED
      //////// right particles animations
      let particlesRight = gsap.utils.toArray('.right-sided .text-container');

      particlesRight.forEach( (currentValue, index, arr) => {
        let particlesTL = gsap.timeline();
        particlesTL.addLabel('start')
        .from( $(`#${$(currentValue).attr('id')}`).find('.product-logo') , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: -100,
          scale: 0.5,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.hero-text h2') , {z: 0.1, rotationZ: 0.01, force3D: true,
          x: -100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.hero-text p') , {z: 0.1, rotationZ: 0.01, force3D: true,
          x: 100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
        if($(`#${$(currentValue).attr('id')}`).find('.hero-text .play-btn-container')){
          particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.play-btn-container') , {z: 0.1, rotationZ: 0.01, force3D: true,
            y: 100,
            autoAlpha: 0,
            duration: 0.5,
            ease: 'power1.out',
            scrollTrigger: {
              trigger: $(`#${$(currentValue).attr('id')}`).parent(),
              scrub: 1,
              start: 'top bottom',
              end: 'bottom bottom'
            }
          }, '-=0.4');
        }
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.cta') , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
      });
      //////// LEFT SIDED
      //////// left particles animations
      let particlesLeft = gsap.utils.toArray('.left-sided .text-container');

      particlesLeft.forEach( (currentValue, index, arr) => {
        let particlesTL = gsap.timeline();
        particlesTL.addLabel('start')
        .from( $(`#${$(currentValue).attr('id')}`).find('.product-logo') , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: -100,
          scale: 0.5,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        });
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.hero-text h2') , {z: 0.1, rotationZ: 0.01, force3D: true,
          x: 100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.hero-text p') , {z: 0.1, rotationZ: 0.01, force3D: true,
          x: -100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
        if($(`#${$(currentValue).attr('id')}`).find('.hero-text .play-btn-container')){
          particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.play-btn-container') , {z: 0.1, rotationZ: 0.01, force3D: true,
            y: 100,
            autoAlpha: 0,
            duration: 0.5,
            ease: 'power1.out',
            scrollTrigger: {
              trigger: $(`#${$(currentValue).attr('id')}`).parent(),
              scrub: 1,
              start: 'top bottom',
              end: 'bottom bottom'
            }
          }, '-=0.4');
        }
        particlesTL.from( $(`#${$(currentValue).attr('id')}`).find('.cta') , {z: 0.1, rotationZ: 0.01, force3D: true,
          y: 100,
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: $(`#${$(currentValue).attr('id')}`).parent(),
            scrub: 1,
            start: 'top bottom',
            end: 'bottom bottom'
          }
        }, '-=0.4');
      });


      ////// dango

      gsap.from('#dango-particles .dango-monkey', {z: 0.1, rotationZ: 0.01, force3D: true,
        y: 200,
        x: 100,
        scale: 1.5,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '#dango-product',
          scrub: 2,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });
      gsap.from('#dango-particles .dango-player-container', {z: 0.1, rotationZ: 0.01, force3D: true,
        x: 200,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '#dango-product',
          scrub: 1,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });

    }, 0);
  }

  ngOnDestroy(): void {
  }


}
