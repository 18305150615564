import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  faPaperPlane,
  faUser,
  faAt,
  faPhone,
  faComment,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  faPaperPlane = faPaperPlane;
  faUser = faUser;
  faAt = faAt;
  faPhone = faPhone;
  faComment = faComment;
  faCheck = faCheck;
  faTimes = faTimes;
  // @ViewChild('deleteSwal');

  @Input() formHeader: string;

  // contactUsForm;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;
  isSent: boolean = false;

  userName: string = '';
  userEmail: string = '';
  userPhone: string = '';
  userCompany: string = '';
  userJob: string = '';
  userMessage: string = '';
  submitDate: string = '';

  contactUsForm = new FormGroup({
    Name: new FormControl('', Validators.required),
    Email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    Phone: new FormControl('', [Validators.required, Validators.pattern(/^[+]{0,1}[\d]{9,13}$/)]),
    Company: new FormControl(''),
    Title: new FormControl(''),
    Message: new FormControl('', Validators.required),
    Date: new FormControl(''),
    utm_source: new FormControl(''),
    utm_medium: new FormControl(''),
    utm_campaign: new FormControl(''),
    utm_content: new FormControl('')
  });

  constructor() { }

  ngOnInit(): void {  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  submitContactUs() {
    if (this.contactUsForm.valid) {

     
      // emailjs.send("service_s90ajzh", "template_0brk7bw", {
      //   to_name: 'Admin',
      //   from_name: this.contactUsForm.value.Name,
      //   from_email: this.contactUsForm.value.Email,
      //   from_phone: this.contactUsForm.value.Phone,
      //   from_company: this.contactUsForm.value.Company,
      //   from_title: this.contactUsForm.value.Title,
      //   message: this.contactUsForm.value.Message,
      // }, 'ibbFoouQH3_XaicE7');

      // alert('sent');
      // this.contactUsForm.reset();

       // if(!this.contactUsForm.controls){
    //   this.contactUsForm.addControl('Name', new FormControl('', Validators.required));
    //   this.contactUsForm.addControl('Email', new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]));
    //   this.contactUsForm.addControl('Phone', new FormControl('', [Validators.required, Validators.pattern(/^[+]{0,1}[\d]{9,13}$/)]));
    //   this.contactUsForm.addControl('Company', new FormControl(''));
    //   this.contactUsForm.addControl('Title', new FormControl(''));
    //   this.contactUsForm.addControl('Message', new FormControl('', Validators.required));
    //   this.contactUsForm.addControl('Date', new FormControl(''));
    // }

    if(this.contactUsForm.valid){
      const dateNow = new Date().toUTCString();
      this.contactUsForm.controls.Date.setValue(dateNow);
      this.userName = this.contactUsForm.value.Name;
      this.userEmail = this.contactUsForm.value.Email;
      this.userPhone = this.contactUsForm.value.Phone;
      this.userCompany = this.contactUsForm.value.Company;
      this.userJob = this.contactUsForm.value.Title;
      this.userMessage = this.contactUsForm.value.Message;
      this.submitDate = this.contactUsForm.value.Date;

      if(this.getParameterByName("utm_source")){
        this.contactUsForm.controls.utm_source.setValue(this.getParameterByName("utm_source"));
      }
      if(this.getParameterByName("utm_medium")){
        this.contactUsForm.controls.utm_medium.setValue(this.getParameterByName("utm_medium"));
      }
      if(this.getParameterByName("utm_campaign")){
        this.contactUsForm.controls.utm_campaign.setValue(this.getParameterByName("utm_campaign"));
      }
      if(this.getParameterByName("utm_content")){
        this.contactUsForm.controls.utm_content.setValue(this.getParameterByName("utm_content"));
      }

      this.isPending = false;
      this.hasError = false;
      this.isLoading = true;
      const formData = new FormData(document.forms.namedItem('contactUsForm'));
      
      const sheetScriptURL = "https://script.google.com/macros/s/AKfycbwFGk0_JNviTFRCDs6wIL-6kts3CrmgnhDi5bB1nxJtQaNEHuTpmyMn3V9KIMtRDfbI/exec";
      fetch(sheetScriptURL, { method: "POST", body: formData })
                .then((resp) => {
                  console.log(resp);
                    this.contactUsForm.reset();

                    this.contactUsForm.markAsPristine();
                    this.contactUsForm.markAsUntouched();
                    this.contactUsForm.updateValueAndValidity();

                    this.isLoading = false;
                    this.hasError = false;
                    this.isPending = true;
                    this.isSent = true;
                    setTimeout(() => {
                      this.isSent = false;
                    }, 6000);
                    // console.log(this.userName);
                    // console.log(this.userEmail);
                    // console.log(this.userPhone);
                    // console.log(this.userCompany);
                    // console.log(this.userJob);
                    // console.log(this.userMessage);
                    // console.log(this.submitDate);
                    // setTimeout( () => {
                    //   this.isLoading = false;
                    //   this.isPending = true;
                    // }, 5000);
                })
                .catch((err) => {
                  console.log(err);
                  this.isLoading = false;
                  this.isPending = true;
                  this.hasError = true;
                  this.isSent = false;
                  // setTimeout(() => {
                  //   this.hasError = false;
                  // }, 10000);
                });
    }
    }
  }
}



// ---------------------------------------------------------------
/**
 * 
    // if(!this.contactUsForm.controls){
    //   this.contactUsForm.addControl('Name', new FormControl('', Validators.required));
    //   this.contactUsForm.addControl('Email', new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]));
    //   this.contactUsForm.addControl('Phone', new FormControl('', [Validators.required, Validators.pattern(/^[+]{0,1}[\d]{9,13}$/)]));
    //   this.contactUsForm.addControl('Company', new FormControl(''));
    //   this.contactUsForm.addControl('Title', new FormControl(''));
    //   this.contactUsForm.addControl('Message', new FormControl('', Validators.required));
    //   this.contactUsForm.addControl('Date', new FormControl(''));
    // }

    if(this.contactUsForm.valid){
      const dateNow = new Date().toUTCString();
      this.contactUsForm.controls.Date.setValue(dateNow);
      this.userName = this.contactUsForm.value.Name;
      this.userEmail = this.contactUsForm.value.Email;
      this.userPhone = this.contactUsForm.value.Phone;
      this.userCompany = this.contactUsForm.value.Company;
      this.userJob = this.contactUsForm.value.Title;
      this.userMessage = this.contactUsForm.value.Message;
      this.submitDate = this.contactUsForm.value.Date;

      if(this.getParameterByName("utm_source")){
        this.contactUsForm.controls.utm_source.setValue(this.getParameterByName("utm_source"));
      }
      if(this.getParameterByName("utm_medium")){
        this.contactUsForm.controls.utm_medium.setValue(this.getParameterByName("utm_medium"));
      }
      if(this.getParameterByName("utm_campaign")){
        this.contactUsForm.controls.utm_campaign.setValue(this.getParameterByName("utm_campaign"));
      }
      if(this.getParameterByName("utm_content")){
        this.contactUsForm.controls.utm_content.setValue(this.getParameterByName("utm_content"));
      }

      this.isPending = false;
      this.hasError = false;
      this.isLoading = true;
      const formData = new FormData(document.forms.namedItem('contactUsForm'));
      // console.log(formData);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }
      // const sheetScriptURL = "https://script.google.com/macros/s/AKfycbzJcoEyCdyMqXnGhkOB_s6VdtcDxEmn1OWewFK6U7BEbNUdFavPhKBwXrz1NdjGKAtikg/exec";
      // const sheetScriptURL = "https://script.google.com/macros/s/AKfycbwq7frRV80pEpg9JdkNpLu29rnK_AaPa8rrSMv4U5vGE1M_mVKNCOGjmD0e6XCdaeU/exec?ContactUs";
      const sheetScriptURL = "https://script.google.com/macros/s/AKfycbzMmZMqhEI55i5e2vPqyfjsF2il8FeHSq50V8McYSitPq5dbSFiCb8Xlisg7M2v9CI_/exec?ContactUs";
      fetch(sheetScriptURL, { method: "POST", body: formData })
                .then((resp) => {
                  console.log(resp);
                    this.contactUsForm.reset();

                    this.contactUsForm.markAsPristine();
                    this.contactUsForm.markAsUntouched();
                    this.contactUsForm.updateValueAndValidity();

                    this.isLoading = false;
                    this.hasError = false;
                    this.isPending = true;
                    this.isSent = true;
                    setTimeout(() => {
                      this.isSent = false;
                    }, 6000);
                    // console.log(this.userName);
                    // console.log(this.userEmail);
                    // console.log(this.userPhone);
                    // console.log(this.userCompany);
                    // console.log(this.userJob);
                    // console.log(this.userMessage);
                    // console.log(this.submitDate);
                    // setTimeout( () => {
                    //   this.isLoading = false;
                    //   this.isPending = true;
                    // }, 5000);
                })
                .catch((err) => {
                  console.log(err);
                  this.isLoading = false;
                  this.isPending = true;
                  this.hasError = true;
                  this.isSent = false;
                  // setTimeout(() => {
                  //   this.hasError = false;
                  // }, 10000);
                });
    }*/