<div id="contact-form-wrapper">
    <h1>{{ formHeader }}</h1>
    <form id="contact-form" name="contactUsForm" [formGroup]="contactUsForm" (ngSubmit)="submitContactUs()">
        <div class="contact-form-groups">
            <div class="form-group-wrapper">
                <div class="form-group">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>Your name</mat-label>
                        <input name="Name" formControlName="Name" id="Name" matInput type="text" required />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>Email address</mat-label>
                        <input name="Email" formControlName="Email" id="Email" matInput type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>Phone number</mat-label>
                        <input name="Phone" formControlName="Phone" id="Phone" matInput type="phone" pattern="^[+]{0,1}[\d]{9,13}$" required />
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group-wrapper">
              <div class="form-group">
                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>Company</mat-label>
                    <input name="Company" formControlName="Company" id="Company" matInput type="text" />
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>Job Title</mat-label>
                    <input name="Title" formControlName="Title" id="Title" matInput type="text" />
                </mat-form-field>
              </div>
            </div>
            <div class="form-group-wrapper">
                <div class="form-group">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>Quick Brief</mat-label>
                        <textarea name="Message" formControlName="Message" id="Message" matInput rows="8" required></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <input name="Date" formControlName="Date" id="Date" type="hidden" />

        <input name="utm_source" formControlName="utm_source" id="utm_source" type="hidden" />
        <input name="utm_medium" formControlName="utm_medium" id="utm_medium" type="hidden" />
        <input name="utm_campaign" formControlName="utm_campaign" id="utm_campaign" type="hidden" />
        <input name="utm_content" formControlName="utm_content" id="utm_content" type="hidden" />

        <div id="submit-btn-container" class="form-group">
            <button mat-raised-button (click)="submitContactUs()" color="accent" type="submit" [disabled]="isLoading">SUBMIT <fa-icon *ngIf="hasError" [icon]="faTimes"></fa-icon><fa-icon *ngIf="isSent" [icon]="faCheck"></fa-icon><fa-icon *ngIf="isPending && !isSent && !hasError" [icon]="faPaperPlane"></fa-icon><mat-spinner *ngIf="isLoading" color="primary"></mat-spinner></button>
        </div>
        <div class="form-submit-feedback {{isSent? 'success': ''}} {{hasError? 'error': ''}}">
          <fa-icon *ngIf="isSent" [icon]="faCheck"></fa-icon>
          <fa-icon *ngIf="hasError" [icon]="faTimes"></fa-icon>
          <p *ngIf="isSent">Your message has been sent successfully, we will contact you as soon as possible.</p>
          <p *ngIf="hasError">Sorry, something went wrong while sending your message. Please try again and if the problem still exist you can reach us via Phone: <a href="tel:+20225259910" class="magic-hover magic-hover__square">+2 (02) 2525 9910</a> | <a href="tel:+20225256793" class="magic-hover magic-hover__square">+2 (02) 2525 6793</a> or Email: <a href="mailto:info@5d-agency.com" class="magic-hover magic-hover__square">info@5d-agency.com</a></p>
        </div>

    </form>
</div>

<!-- <swal
  #email_success
  title="Email Sent Successfully!"
  text="Thank you, we will contact you as soon as possible."
  icon="success">
</swal> -->