<!-- <div class="scroll-container"> -->
    <main id="strategy-container">
        <div class="service-hero-container">
            <div class="service-hero-wrapper d-flex flex-column justify-content-between align-items-center">
                <div class="header-wrapper d-flex flex-column justify-content-center align-items-center">
                    <div class="text-wrapper container">
                        <div class="sectionLogoContainer">
                            <img src="../../../../assets/img/strategy/strategyLogo.svg" />
                        </div>
                        <h1>STRATEGY</h1>
                        <p class="sub-header">THE NEED TO EVOLVE</p>
                        <p class="text">
                          The success of every project comes from knowing where you stand and where your competition stands while identifying your right audiences and their needs..
                          <br>
                          That's all irrelevant if you don't have our brilliant team of strategists working with you.
                        </p>
                    </div>
                    <div class="icons-Wrapper container">
                        <div id="icons-swiper" class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/strategy/strategyServiceIcon1.png"/>
                                    </div>
                                    <p>First Glance Analysis</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/strategy/strategyServiceIcon2.png"/>
                                    </div>
                                    <p>Marketing Approach</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/strategy/strategyServiceIcon3.png"/>
                                    </div>
                                    <p>Ideation & Execution</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/strategy/strategyServiceIcon4.png"/>
                                    </div>
                                    <p>Evaluation & Optimization</p>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>

                            <!-- <div id="icons-nav-btns-container" class="nav-btns-container">
                                <button class="prev-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                                </button>
                                <button class="next-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronRight"></fa-icon>
                                </button>
                            </div> -->

                        </div>

                    </div>

                </div>
            </div>
        </div>


        <div class="showcases">
            <div id="small-pawns" class="pawns-container"></div>
            <div id="medium-pawns" class="pawns-container"></div>
            <div id="big-pawns" class="pawns-container"></div>



            <!-- ROOK -->
            <div id="rook-showcase" class="showcase-container">
                <div id="rook-line-container" class="line-container line-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.29 92.8" class="line">
                        <defs>
                            <style>
                                .cls-1{
                                    fill:none;
                                    stroke:rgba(255, 255, 255,1);
                                    stroke-linecap:round;
                                    stroke-miterlimit:10;
                                    stroke-dasharray:4 4;
                                    stroke-width: 0.5px;
                                }
                                .cls-2{
                                    stroke:rgb(243, 239, 245)!important;
                                    stroke-width: 0.5px;
                                    stroke-dasharray:1 2;
                                }
                            </style>
                            <mask id="rook-dash">
                                <path class="cls-1 cls-2" d="M4,22.45C14.1,32.63,44.76,60.18,70.33,50,103,37,86,11.17,86,11.17S75.21-6.75,52.08,3.86c-19.91,9.14-23,41.28-23,41.28s-2.64,31.27,22,41.57,36.6,6.46,73.26-12.21,52.09-14.42,52.09-14.42,19.11.05,44,26.81"/>
                            </mask>
                        </defs>
                        <path id="rook-line" class="cls-1" mask="url(#rook-dash)" d="M4,22.45C14.1,32.63,44.76,60.18,70.33,50,103,37,86,11.17,86,11.17S75.21-6.75,52.08,3.86c-19.91,9.14-23,41.28-23,41.28s-2.64,31.27,22,41.57,36.6,6.46,73.26-12.21,52.09-14.42,52.09-14.42,19.11.05,44,26.81"/>
                    </svg>
                </div>
                <div id="rook-bg-container" class="bg-container bg-left">
                    <!-- <img src="../../../../assets/img/strategy/phone-left.png" class="phone"/>
                    <img src="../../../../assets/img/strategy/graph1.svg" class="graph"/>
                    <img src="../../../../assets/img/strategy/Rook.png" class="particle"/> -->
                    <picture>
                        <source media="(max-width:599px)" srcset="../../../../assets/img/strategy/rookAll-mobile.png">
                        <source media="(min-width:600px)" srcset="../../../../assets/img/strategy/rookAll.png">
                        <img src="../../../../assets/img/strategy/rookAll.png" class="all"/>
                    </picture>

                </div>
                <div id="rook-text-container" class="text-container text-container-left d-flex flex-column justify-content-center align-items-center">
                    <h2>Situational Analysis</h2>
                    <p>
                        Providing our clients with on-going Situational/SWOT analysis to keep grasp of our performance and work on points to develop.
                    </p>
                </div>
            </div>

            <!-- KNIGHT -->
            <div class="showcase-container">
                <div id="knight-line-container" class="line-container line-right">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.29 92.8" class="line">
                        <defs>
                            <style>
                                .cls-1{
                                    fill:none;
                                    stroke:rgba(255, 255, 255,1);
                                    stroke-linecap:round;
                                    stroke-miterlimit:10;
                                    stroke-dasharray:4 4;
                                    stroke-width: 0.5px;
                                }
                                .cls-2{
                                    stroke:rgb(243, 239, 245)!important;
                                    stroke-width: 0.5px;
                                    stroke-dasharray:1 2;
                                }
                            </style>
                            <mask id="knight-dash">
                                <path class="cls-1 cls-2" d="M219.34,22.45C209.19,32.63,178.53,60.18,153,50c-32.67-13-15.67-38.83-15.67-38.83s10.79-17.92,33.92-7.3c19.91,9.14,23,41.28,23,41.28s2.64,31.27-22,41.57S135.62,93.17,99,74.5,46.87,60.08,46.87,60.08s-19.11.05-44,26.81"/>
                            </mask>
                        </defs>
                        <path id="knight-line" class="cls-1" mask="url(#knight-dash)" d="M219.34,22.45C209.19,32.63,178.53,60.18,153,50c-32.67-13-15.67-38.83-15.67-38.83s10.79-17.92,33.92-7.3c19.91,9.14,23,41.28,23,41.28s2.64,31.27-22,41.57S135.62,93.17,99,74.5,46.87,60.08,46.87,60.08s-19.11.05-44,26.81"/>
                    </svg>
                </div>
                <div id="knight-bg-container" class="bg-container bg-right">
                    <!-- <img src="../../../../assets/img/strategy/phone-right.png" class="phone"/>
                    <img src="../../../../assets/img/strategy/graph2.svg" class="graph"/>
                    <img src="../../../../assets/img/strategy/Knight.png" class="particle"/> -->
                    <picture>
                        <source media="(max-width:599px)" srcset="../../../../assets/img/strategy/knightAll-mobile.png">
                        <source media="(min-width:600px)" srcset="../../../../assets/img/strategy/knightAll.png">
                        <img src="../../../../assets/img/strategy/knightAll.png" class="all"/>
                    </picture>
                </div>
                <div id="knight-text-container" class="text-container text-container-right d-flex flex-column justify-content-center align-items-center">
                    <h2>Competitive Analysis</h2>
                    <p>
                        Putting our competitors under the microscope to monitor their performance and know how to keep our clients ahead of their game.
                    </p>
                </div>
            </div>

            <!-- BISHOPS -->
            <div class="showcase-container">
                <div id="bishops-line-container" class="line-container line-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.29 92.8" class="line">
                        <defs>
                            <style>
                                .cls-1{
                                    fill:none;
                                    stroke:rgba(255, 255, 255,1);
                                    stroke-linecap:round;
                                    stroke-miterlimit:10;
                                    stroke-dasharray:4 4;
                                    stroke-width: 0.5px;
                                }
                                .cls-2{
                                    stroke:rgb(243, 239, 245)!important;
                                    stroke-width: 0.5px;
                                    stroke-dasharray:1 2;
                                }
                            </style>
                            <mask id="bishops-dash">
                                <path class="cls-1 cls-2" d="M4,22.45C14.1,32.63,44.76,60.18,70.33,50,103,37,86,11.17,86,11.17S75.21-6.75,52.08,3.86c-19.91,9.14-23,41.28-23,41.28s-2.64,31.27,22,41.57,36.6,6.46,73.26-12.21,52.09-14.42,52.09-14.42,19.11.05,44,26.81"/>
                            </mask>
                        </defs>
                        <path id="bishops-line" class="cls-1" mask="url(#bishops-dash)" d="M4,22.45C14.1,32.63,44.76,60.18,70.33,50,103,37,86,11.17,86,11.17S75.21-6.75,52.08,3.86c-19.91,9.14-23,41.28-23,41.28s-2.64,31.27,22,41.57,36.6,6.46,73.26-12.21,52.09-14.42,52.09-14.42,19.11.05,44,26.81"/>
                    </svg>
                </div>
                <div id="bishops-bg-container" class="bg-container bg-left">
                    <!-- <img src="../../../../assets/img/strategy/phone-left.png" class="phone"/>
                    <img src="../../../../assets/img/strategy/graph3.png" class="graph large"/>
                    <img src="../../../../assets/img/strategy/Bishops.png" class="particle"/> -->
                    <picture>
                        <source media="(max-width:599px)" srcset="../../../../assets/img/strategy/bishopsAll-mobile.png">
                        <source media="(min-width:600px)" srcset="../../../../assets/img/strategy/bishopsAll.png">
                        <img src="../../../../assets/img/strategy/bishopsAll.png" class="all"/>
                    </picture>
                </div>
                <div id="bishops-text-container" class="text-container text-container-left d-flex flex-column justify-content-center align-items-center">
                    <h2>Touch Point Strategy</h2>
                    <p>
                        Using Insights from industry and analysis to create a customized strategy for our client’s to achieve their objectives and enrich their communication.
                    </p>
                </div>
            </div>

            <!-- QUEEN -->
            <div class="showcase-container">
                <div id="queen-line-container" class="line-container line-right">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.29 92.8" class="line">
                        <defs>
                            <style>
                                .cls-1{
                                    fill:none;
                                    stroke:rgba(255, 255, 255,1);
                                    stroke-linecap:round;
                                    stroke-miterlimit:10;
                                    stroke-dasharray:4 4;
                                    stroke-width: 0.5px;
                                }
                                .cls-2{
                                    stroke:rgb(243, 239, 245)!important;
                                    stroke-width: 0.5px;
                                    stroke-dasharray:1 2;
                                }
                            </style>
                            <mask id="queen-dash">
                                <path class="cls-1 cls-2" d="M219.34,22.45C209.19,32.63,178.53,60.18,153,50c-32.67-13-15.67-38.83-15.67-38.83s10.79-17.92,33.92-7.3c19.91,9.14,23,41.28,23,41.28s2.64,31.27-22,41.57S135.62,93.17,99,74.5,46.87,60.08,46.87,60.08s-19.11.05-44,26.81"/>
                            </mask>
                        </defs>
                        <path id="queen-line" class="cls-1" mask="url(#queen-dash)" d="M219.34,22.45C209.19,32.63,178.53,60.18,153,50c-32.67-13-15.67-38.83-15.67-38.83s10.79-17.92,33.92-7.3c19.91,9.14,23,41.28,23,41.28s2.64,31.27-22,41.57S135.62,93.17,99,74.5,46.87,60.08,46.87,60.08s-19.11.05-44,26.81"/>
                    </svg>
                </div>
                <div id="queen-bg-container" class="bg-container bg-right">
                    <!-- <img src="../../../../assets/img/strategy/phone-right.png" class="phone"/>
                    <img src="../../../../assets/img/strategy/graph4.svg" class="graph"/>
                    <img src="../../../../assets/img/strategy/Queen.png" class="particle"/> -->
                    <picture>
                        <source media="(max-width:599px)" srcset="../../../../assets/img/strategy/queenAll-mobile.png">
                        <source media="(min-width:600px)" srcset="../../../../assets/img/strategy/queenAll.png">
                        <img src="../../../../assets/img/strategy/queenAll.png" class="all"/>
                    </picture>
                </div>
                <div id="queen-text-container" class="text-container text-container-right d-flex flex-column justify-content-center align-items-center">
                    <h2>Road Mapping</h2>
                    <p>
                        Turning our collaborative vision, into a measurable reality, providing alignment to all of our communication functions with client’s vision in mind.
                    </p>
                </div>
            </div>

            <!-- KING -->
            <div class="showcase-container">
                <div id="king-bg-container" class="bg-container bg-left">
                    <!-- <img src="../../../../assets/img/strategy/phone-left.png" class="phone"/>
                    <img src="../../../../assets/img/strategy/graph5.svg" class="graph"/>
                    <img src="../../../../assets/img/strategy/King.png" class="particle"/> -->
                    <picture>
                        <source media="(max-width:599px)" srcset="../../../../assets/img/strategy/kingAll-mobile.png">
                        <source media="(min-width:600px)" srcset="../../../../assets/img/strategy/kingAll.png">
                        <img src="../../../../assets/img/strategy/kingAll.png" class="all"/>
                    </picture>
                </div>
                <div id="king-text-container" class="text-container text-container-left d-flex flex-column justify-content-center align-items-center">
                    <h2>Prioritization Budgeting</h2>
                    <p>
                        Using Insights from industry and analysis to create a customized strategy for our client’s to achieve their objectives and enrich their communication.
                    </p>
                </div>
            </div>


        </div>

        <div class="next-container">
            <div class="next-wrapper">
                <p>Next</p>
                <h1>
                    <a routerLink="/creative" class="magic-hover magic-hover__square">
                        CREATIVE
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->
