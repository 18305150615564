import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faArrowUp,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

import {magicMouse} from '../../../assets/js/magic_mouse.js';

declare var $;


@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit, AfterViewInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faArrowUp = faArrowUp;
  faChevronUp = faChevronUp;

  scrollbar;

  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.header-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: -100,
      // scale: 4,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power3.easeOut',
    }, '-=2')
    .from( '.objective-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: -100,
      autoAlpha: 0,
      duration: 1.2,
      ease: 'Power3.easeOut',
    }, '-=1.55')
    .from( '.clients-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: -100,
      autoAlpha: 0,
      duration: 1.2,
      ease: 'Power3.easeOut',
    }, '-=0.55')
    .from( '.clients-wrapper .clients-grid-wrapper .client-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: 100,
      autoAlpha: 0,
      stagger: 0.1,
      duration: 0.8,
      ease: 'Power3.easeOut'
    })
    .from( '.testimonials-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: -100,
      autoAlpha: 0,
      duration: 1.2,
      ease: 'Power3.easeOut',
    }, '-=1');
    
    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);

    // Initializing TESTIMONIALS swiper carousel
    const testimonialsSwiper = new swiper('#testimonials-swiper', {
      // Optional parameters
      direction: 'horizontal',
      speed: 1800,
      grabCursor: true,
      autoHeight: false,
      autoplay:  {
        delay: 8000,
        disableOnInteraction: false,
      },
      loop: false,
      slidesPerView: 1,
      preventInteractionOnTransition: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      resistance: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      mousewheel: false,
      navigation: {
        nextEl: '.next-btn',
        prevEl: '.prev-btn',
      },
      scrollbar: false,
    });

    $('.light-up-cursor').on('mouseenter', () => {
      $('#magicMouseCursor').addClass('clientHover');
    });
    $('.light-up-cursor').on('mouseleave', () => {
      $('#magicMouseCursor').removeClass('clientHover');
    });
  }

  ngAfterViewInit(): void {
    setTimeout( () => {

      // const headerTL = gsap.timeline();
      // headerTL.from( '.header-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -100,
      //   // scale: 4,
      //   autoAlpha: 0,
      //   duration: 2,
      //   ease: 'Power3.easeOut',
      // })
      // .from( '.objective-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -100,
      //   autoAlpha: 0,
      //   duration: 1.2,
      //   ease: 'Power3.easeOut',
      // }, '-=1.55')
      // .from( '.clients-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -100,
      //   autoAlpha: 0,
      //   duration: 1.2,
      //   ease: 'Power3.easeOut',
      // }, '-=0.55')
      // .from( '.testimonials-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -100,
      //   autoAlpha: 0,
      //   duration: 1.2,
      //   ease: 'Power3.easeOut',
      // }, '-=1');
  
      //// on scroll
      // gsap.from( '.clients-wrapper h3' , {
      //   y: 100,
      //   autoAlpha: 0,
      //   ease: 'power1.out',
      //   scrollTrigger: {
      //     trigger: '.clients-wrapper',
      //     scrub: 1,
      //     start: 'top bottom',
      //     end: 'bottom bottom'
      //   }
      // });
      // gsap.from( '.clients-wrapper p' , {
      //   y: 100,
      //   autoAlpha: 0,
      //   ease: 'power1.out',
      //   scrollTrigger: {
      //     trigger: '.clients-wrapper',
      //     scrub: 1,
      //     start: 'top bottom',
      //     end: 'bottom bottom'
      //   }
      // });

      // gsap.from( '.clients-wrapper .clients-grid-wrapper .client-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -100,
      //   autoAlpha: 0,
      //   stagger: 0.1,
      //   ease: 'power1.out',
      //   scrollTrigger: {
      //     trigger: '.clients-wrapper',
      //     scrub: 2,
      //     start: 'top bottom',
      //     end: 'bottom bottom'
      //   }
      // });

      gsap.from( '.testimonials-container h3' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.testimonials-container',
          scrub: 2,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });
      gsap.from( '#testimonials-swiper' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.testimonials-container',
          scrub: 2,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });
      gsap.from( '.next-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '.next-container',
          scrub: 3,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      });

    }, 0);

  }

}
