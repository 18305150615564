<!-- ======================================== FOOTER ========================================== -->
<footer>
    <!-- <div class="contact-info">
        <h1 class="title">KEEP IN TOUCH</h1>
        <div class="info-container">
            <div class="sub-title">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                <span>Maadi, Cairo, Egypt  |  Free Zone, Dubai, UAE</span>
            </div>
        </div>
        <div class="info-container">
            <div class="sub-title">
                <fa-icon [icon]="faMobileAlt"></fa-icon>
                <span>+2 02 2525 9910 - 2525 6793</span>
            </div>
        </div>
        <div class="info-container">
            <div class="sub-title">
                <fa-icon [icon]="faEnvelope"></fa-icon>
                <span>info@5d-agency.com</span>
            </div>
        </div>
    </div> -->
    <div class="shortcut-links">
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <a class="nav-link magic-hover magic-hover__square" routerLink="/who-we-are" data-slide-to="aboutPage">WHO WE ARE</a>
            </li>
            <li class="nav-item">
                <a class="nav-link magic-hover magic-hover__square" routerLink="/contact-us" data-slide-to="contactPage">CONTACT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link magic-hover magic-hover__square" routerLink="/get-quote" data-slide-to="getQuotePage">GET QUOTE</a>
          </li>
            <li class="nav-item">
                <a class="nav-link magic-hover magic-hover__square" routerLink="/careers" data-slide-to="careersPage">CAREERS</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link magic-hover magic-hover__square" routerLink="" data-slide-to="termsPage">TERMS</a>
            </li>
            <li class="nav-item">
                <a class="nav-link magic-hover magic-hover__square" routerLink="" data-slide-to="privacyPage">PRIVACY</a>
            </li> -->
        </ul>
    </div>
    <div class="copyrights">
        <p>
            2022 Copyrights &copy; 5d Agency
        </p>
    </div>
</footer>
