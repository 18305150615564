<!-- <div class="scroll-container"> -->
    <main id="careers-container">
        <div class="container d-flex flex-column justify-content-center align-items-center">
            <div class="header-wrapper">
                <h1>CAREERS</h1>
                <h2>JOIN THE FAMILY</h2>
            </div>
            <div class="objective-wrapper">
                <p>Send us your CV and we will reach you</p>
            </div>



        </div>
        <div id="careers-form-wrapper">
            <!-- <h1>Tell Us About Yourself</h1> -->
            <form id="careers-form" #careers_form name="careersForm" [formGroup]="careersForm" (ngSubmit)="careersMail()" enctype="multipart/form-data">
                <div class="careers-form-groups">
                    <div class="form-group-wrapper">
                        <div class="form-group">
                            <mat-form-field floatLabel="auto" appearance="fill">
                                <mat-label>Your name</mat-label>
                                <input name="name" formControlName="name" matInput type="text" required />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field floatLabel="auto" appearance="fill">
                                <mat-label>Email address</mat-label>
                                <input name="email" formControlName="email" matInput type="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field floatLabel="auto" appearance="fill">
                                <mat-label>Phone number</mat-label>
                                <input name="phone" formControlName="phone" matInput type="phone" pattern="^[+]{0,1}[\d]{9,13}$" required />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group-wrapper">

                        <div class="form-group">
                            <!-- <mat-form-field floatLabel="auto" appearance="legacy"> -->
                                <!-- <mat-label>Upload Your CV</mat-label> -->
                                <!-- <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="cvFile" ngModel name="userCV" (change)="handleUpload($event)" required>
                                    <label class="custom-file-label" for="customFile">Upload Your CV</label>
                                </div> -->
                            <!-- </mat-form-field> -->

                            <div class="myfilebrowser">

                                <!-- <mat-toolbar> -->
                                    <mat-form-field floatLabel="auto" appearance="fill">
                                        <mat-label>Attach your CV</mat-label>
                                        <!-- Readonly Input to show File names -->
                                        <input type="text" matInput [(value)]="userCVName" readonly name="Attachment" required />
                                        <!-- Browse Button -->
                                        <button mat-icon-button color="primary">
                                            Browse
                                        </button>
                                        <!-- Fetch selected filed on change -->
                                        <input type="file" id="fileUpload" (change)="fileChangeEvent($event)" required name="cv" formControlName="cv" #userCVRef
                                              accept=".pdf, .doc, .docx" />
                                        <mat-error *ngIf="!errorMessage">{{errorMessage}}</mat-error>
                                    </mat-form-field>


                                    <input type="hidden" name="_template" value="table">
                                    <input type="hidden" name="_subject" value="Careers New Applicant">

                                <!-- </mat-toolbar> -->

                                <!-- <a [href]="userCVuri" download="{{userCVName}}">download {{userCVName}}</a> -->

                            </div>

                        </div>
                    </div>
                </div>
                <div id="submit-btn-container" class="form-group">
                    <button mat-raised-button color="accent" type="submit" [disabled]="isLoading">{{ isLoading ? 'SENDING' : 'SEND' }} <fa-icon *ngIf="hasError" [icon]="faTimes"></fa-icon><fa-icon *ngIf="isSent" [icon]="faCheck"></fa-icon><fa-icon *ngIf="isPending && !isSent && !hasError" [icon]="faPaperPlane"></fa-icon><mat-spinner *ngIf="isLoading" color="primary"></mat-spinner></button>
                </div>

            </form>
        </div>
        <!-- <embed src="" width="100%" height="100%" type="application/pdf"> -->
        <div class="next-container">
            <div class="next-wrapper">
                <p>Let's Talk</p>
                <h1>
                    <a routerLink="/contact-us" class="magic-hover magic-hover__square">
                        CONTACT US
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->





