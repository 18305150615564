import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faMapMarkerAlt,
  faPhoneAlt,
  faPaperPlane,
  faHome,
  faCheckSquare,
  faCheckCircle,
  faCheck,
  faSmile,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';

import {magicMouse} from '../../../assets/js/magic_mouse.js';


declare var $;
declare let gtag: Function;


@Component({
  selector: 'app-get-quote-thankyou',
  templateUrl: './get-quote-thankyou.component.html',
  styleUrls: ['./get-quote-thankyou.component.scss']
})
export class GetQuoteThankyouComponent implements OnInit {

  faChevronLeft = faChevronLeft;
  faHome = faHome;
  faCheckSquare = faCheckSquare;
  faCheckCircle = faCheckCircle;
  faCheck = faCheck;
  faSmile = faSmile;
  faDownload = faDownload;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;

  userName = '';
  userEmail = '';
  userPhone = '';
  userMessage = '';


  getQuoteFormHeader: string = `Let's Talk`;


  constructor() {
    gtag('js', new Date());
    gtag('config', 'AW-10928211236');
  }

  ngOnInit(): void {

    //<!-- Event snippet for Get Quote Thank You conversion page -->
    gtag('event', 'conversion', {'send_to': 'AW-10928211236/boo1CIDe48MDEKSK_doo'});

    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      CSSRulePlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);

    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    .from( '.header-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -100,
        autoAlpha: 0,
        duration: 2,
        ease: 'Power3.easeOut',
    }, '-=2')
    .from( '.get-quote-form-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: -50,
        autoAlpha: 0,
        ease: 'Power3.easeOut',
        duration: 1.2,
    }, '-=1.55');


    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);
  }

  ngAfterViewInit(): void {

    setTimeout( () => {



      // on scroll
      // gsap.from( '.get-quote-form-wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
      //   y: -50,
      //   autoAlpha: 0,
      //   ease: 'power1.out',
      //   scrollTrigger: {
      //     trigger: '.get-quote-form-wrapper',
      //     scrub: 2,
      //     start: 'top bottom',
      //     end: 'bottom bottom'
      //   }
      // });

      gsap.from( '#download-btn-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
        scale: 0,
        autoAlpha: 0,
        ease: 'power1.out',
        delay: 1.5,
        // scrollTrigger: {
        //   trigger: '.next-container',
        //   scrub: 3,
        //   start: 'top bottom',
        //   end: 'bottom bottom'
        // }
      });

      gsap.from( '.next-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
        y: 100,
        autoAlpha: 0,
        ease: 'power1.out',
        delay: 2,
        // scrollTrigger: {
        //   trigger: '.next-container',
        //   scrub: 3,
        //   start: 'top bottom',
        //   end: 'bottom bottom'
        // }
      });
    }, 0);
  }

}
