import { Component, OnInit } from '@angular/core';
import {
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faPlay,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
// import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack';
// import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
// import { Draggable } from 'gsap/Draggable';
// import { EaselPlugin } from 'gsap/EaselPlugin';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
// import { PixiPlugin } from 'gsap/PixiPlugin';
// import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { CustomEase } from 'gsap/CustomEase';
// import { CustomBounce } from 'gsap/CustomBounce';
// import { CustomWiggle } from 'gsap/CustomWiggle';

// import anime.js
import anime from 'animejs/lib/anime.es.js';

// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';


import {magicMouse} from '../../../assets/js/magic_mouse.js';

import * as Plyr from 'plyr';


declare var $;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faPlay = faPlay;
  faDownload = faDownload;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // Animations
    // Register plugins to be used and avoid bundler tree shaking losses
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
    );
    // initialize magic mouse plugin
    const magicmouseoptions = {
      cursorOuter: 'circle-basic',
      hoverEffect: 'circle-move',
      hoverItemMove: true,
      defaultCursor: true,
      outerWidth: 40,
      outerHeight: 40
    };
    magicMouse(magicmouseoptions);

    globalThis.scrollTo(0, 0);


    $('#magicMouseCursor').addClass('is-loading');
    const welcomeTL = gsap.timeline();
    welcomeTL
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 1,
      duration: 1,
      ease: 'Power1.easeOut',
    })
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 2,
      ease: 'Power1.easeOut',
    })
    .from( 'main' , {z: 0.1, rotationZ: 0.01, force3D: true,
      autoAlpha: 0,
      duration: 1,
      ease: 'Power3.easeOut',
    }, '-=2')
    .to( '.loading-container' , {z: 0.1, rotationZ: 0.01, force3D: true,
      y: '-100%',
      autoAlpha: 0,
      duration: 0.1,
      ease: 'Power2.easeOut',
    })

    // .from( '.sectionLogoContainer' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   // y: '500',
    //   // scale: 5,
    //   duration: 2,
    //   autoAlpha: 0,
    //   opacity: 0,
    //   ease: 'power1.out',
    // }, '-=2')
    // .from( '.text-wrapper h1' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=1.55')
    // .from( '.text-wrapper .sub-header' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '100',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55')
    // .from( '.text-wrapper .text' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55')
    // .from( '.icons-Wrapper' , {z: 0.1, rotationZ: 0.01, force3D: true,
    //   y: '-50',
    //   duration: 1.2,
    //   autoAlpha: 0,
    //   ease: 'back.out(1.7)',
    // }, '-=0.55');

    setTimeout(() => {
      $('#magicMouseCursor').removeClass('is-loading');
    }, 3000);






    $('.light-up-cursor').on('mouseenter', () => {
      $('#magicMouseCursor').addClass('clientHover');
    });
    $('.light-up-cursor').on('mouseleave', () => {
      $('#magicMouseCursor').removeClass('clientHover');
    });

  }


}
