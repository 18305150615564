import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// Router
import { Router } from '@angular/router';
import {
  faPaperPlane,
  faUser,
  faAt,
  faPhone,
  faComment,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-get-quote-form',
  templateUrl: './get-quote-form.component.html',
  styleUrls: ['./get-quote-form.component.scss']
})
export class GetQuoteFormComponent implements OnInit {

  faPaperPlane = faPaperPlane;
  faUser = faUser;
  faAt = faAt;
  faPhone = faPhone;
  faComment = faComment;
  faCheck = faCheck;
  faTimes = faTimes;


  @Input() formHeader: string;

  // getQuoteForm;
  isPending: boolean = true;
  hasError: boolean = false;
  isLoading: boolean = false;
  isSent: boolean = false;

  userName: string = '';
  userEmail: string = '';
  userPhone: string = '';
  userCompany: string = '';
  userJob: string = '';
  userMessage: string = '';
  submitDate: string = '';

  getQuoteForm = new FormGroup({
    Name: new FormControl('', Validators.required),
    Email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    Phone: new FormControl('', [Validators.required, Validators.pattern(/^[+]{0,1}[\d]{9,13}$/)]),
    Company: new FormControl(''),
    Title: new FormControl(''),
    Message: new FormControl('', Validators.required),
    Date: new FormControl(''),
    utm_source: new FormControl(''),
    utm_medium: new FormControl(''),
    utm_campaign: new FormControl(''),
    utm_content: new FormControl('')
  });

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    // this.getQuoteForm.valueChanges.subscribe((formValues) => {

    //     console.log(formValues);
    //     this.isPending = true;
    //     this.hasError = false;
    //     this.isLoading = false;

    //     this.userName = formValues.Name;
    //     this.userEmail = formValues.Email;
    //     this.userPhone = formValues.Phone;
    //     this.userMessage = formValues.Message;
    //     this.userMessage = formValues.Date;


    //     console.log(this.userName);
    //     console.log(this.userEmail);
    //     console.log(this.userPhone);
    //     console.log(this.userMessage);
    // });
    // this.getQuoteForm = document.forms.getQuoteForm;
    // console.log(document.forms.getQuoteForm);

  }


  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  submitGetQuote(): void {
    if (this.getQuoteForm.valid) {
      const dateNow = new Date().toUTCString();
      this.getQuoteForm.controls.Date.setValue(dateNow);
      this.userName = this.getQuoteForm.value.Name;
      console.log(this.userName);

      this.userEmail = this.getQuoteForm.value.Email;
      this.userPhone = this.getQuoteForm.value.Phone;
      this.userCompany = this.getQuoteForm.value.Company;
      this.userJob = this.getQuoteForm.value.Title;
      this.userMessage = this.getQuoteForm.value.Message;
      this.submitDate = this.getQuoteForm.value.Date;

      if (this.getParameterByName("utm_source")) {
        this.getQuoteForm.controls.utm_source.setValue(this.getParameterByName("utm_source"));
      }
      if (this.getParameterByName("utm_medium")) {
        this.getQuoteForm.controls.utm_medium.setValue(this.getParameterByName("utm_medium"));
      }
      if (this.getParameterByName("utm_campaign")) {
        this.getQuoteForm.controls.utm_campaign.setValue(this.getParameterByName("utm_campaign"));
      }
      if (this.getParameterByName("utm_content")) {
        this.getQuoteForm.controls.utm_content.setValue(this.getParameterByName("utm_content"));
      }


      this.isPending = false;
      this.hasError = false;
      this.isLoading = true;
      const formData = new FormData(document.forms.namedItem('getQuoteForm'));
      // console.log(formData);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }
      // const sheetScriptURL = "https://script.google.com/macros/s/AKfycbwLX0ccgyXnVJHIUQLHOLCLgfllQz1X6xHQkPrioIkwk-fKSm8c76hS1a9yz5KVJRog/exec";
      // const sheetScriptURL = "https://script.google.com/macros/s/AKfycbwq7frRV80pEpg9JdkNpLu29rnK_AaPa8rrSMv4U5vGE1M_mVKNCOGjmD0e6XCdaeU/exec?GetQoute";
      const sheetScriptURL = "https://script.google.com/macros/s/AKfycbxbvIHczRaVvlQIehMWxthLLkxOVIQ5YBMo3t99DhYLWo_j4MV__hA6Hnq6IQrfPXzuXw/exec";
      fetch(sheetScriptURL, {
        redirect: "follow", method: "POST", body: formData,
        // headers: {
        //   "Content-Type": "text/plain;charset=utf-8",
        // } 
      })
        .then((resp) => {
          console.dir(resp);
          this.isLoading = false;
          this.hasError = false;
          this.isPending = true;
          this.isSent = true;
          this.router.navigateByUrl('/get-quote-thankyou');

          // this.getQuoteForm.reset();
          //   this.hasError = false;
          //   this.isPending = true;
          //   this.isSent = true;
          //   setTimeout(() => {
          //     this.isSent = false;
          //   }, 2000);


          // console.log(this.userName);
          // console.log(this.userEmail);
          // console.log(this.userPhone);
          // console.log(this.userCompany);
          // console.log(this.userJob);
          // console.log(this.userMessage);
          // console.log(this.submitDate);
          // setTimeout( () => {
          //   this.isLoading = false;
          //   this.isPending = true;
          // }, 5000);
        })
        .catch((err) => {
          console.dir(err);
          this.isLoading = false;
          this.isPending = true;
          this.hasError = true;
          this.isSent = false;
          setTimeout(() => {
            this.hasError = false;
          }, 3000);
        });
    }

  }

}
