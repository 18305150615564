<!-- <div class="scroll-container"> -->
    <main id="production-container">
        <div class="service-hero-container">
            <div class="service-hero-wrapper d-flex flex-column justify-content-between align-items-center">
                <div class="header-wrapper d-flex flex-column justify-content-center align-items-center">
                    <div class="text-wrapper container">
                        <div class="sectionLogoContainer">
                            <img src="../../../../assets/img/production/productionLogo.svg" />
                        </div>
                        <h1>DEVELOPMENT</h1>
                        <p class="sub-header">THE WILL TO ACHIEVE</p>
                        <p class="text">
                          From a needle to a rocket.... Apps, web , desktop apps and mobile apps are the things we can create for you
                        </p>
                    </div>
                    <div class="icons-Wrapper container">
                        <div id="icons-swiper" class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/production/productionServiceIcon1.png"/>
                                    </div>
                                    <p>Augmented Reality</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/production/productionServiceIcon2.png"/>
                                    </div>
                                    <p>Games Development</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/production/productionServiceIcon3.png"/>
                                    </div>
                                    <p>Mobile Applications</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/production/productionServiceIcon4.png"/>
                                    </div>
                                    <p>Web Development</p>
                                </div>
                                <div class="icon-container swiper-slide">
                                    <div class="img-container light-up-cursor">
                                        <img src="../../../../assets/img/production/productionServiceIcon5.png"/>
                                    </div>
                                    <p>Ambient Solutions</p>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>

                            <!-- <div id="icons-nav-btns-container" class="nav-btns-container">
                                <button class="prev-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                                </button>
                                <button class="next-btn magic-hover magic-hover__square" type="button">
                                    <fa-icon [icon]="faChevronRight"></fa-icon>
                                </button>
                            </div> -->



                        </div>

                    </div>

                </div>
            </div>
        </div>



        <!-- la vache qui rit PRODUCT -->

        <div id="lavachequirit-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="lavachequirit-particles"  class="particles">
                    <div class="lavachequirit-mobile-app"></div>
                    <div class="lavachequirit-cow"></div>
                </div>
            </div>
            <div id="lavachequirit-text-container" class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/lavachequiritLogo.png" alt="La vache qui rit">
                </div>
                <div class="hero-text">
                    <h2>
                        WISE MOOS
                    </h2>
                    <p>Using La Vache Qui Rit famous cow to create an educating character for kids by using AR technology. the app lets you scan the pack and our favorite cow appears providing you with informative knowledge.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- TRY IT YOURSELF -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>


        <!-- nescafe PRODUCT -->
        <div id="nescafe-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="nescafe-particles"  class="particles">
                    <div class="nescafe-mobile-app"></div>
                    <div class="nescafe-alarms"></div>
                </div>
            </div>
            <div id="nescafe-text-container"  class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/nescafeLogo.png" alt="Nescafe">
                </div>
                <div class="hero-text">
                    <h2>
                        SA7SA7
                    </h2>
                    <p>All your morning needs are combined in one smart app. It's alarming, it's fun, and it gets you through the waking up process. Only 2 things can make it happen; Nescafe and our AR apps.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- PLAY VIDEO -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- lego PRODUCT -->

        <div id="lego-product" class="product-container left-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="lego-particles"  class="particles">
                    <div class="lego-man"></div>
                </div>
            </div>
            <div id="lego-text-container"  class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/legoLogo.png" alt="Lego">
                </div>
                <div class="hero-text">
                    <h2>
                        LEGO HAUNT
                    </h2>
                    <p>The builders of tomorrow just got real with the release of the new LEGO Batman movie we created an AR game, with it you can play your favorite Lego game with real characters and much more fun.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- START BUILDING -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- cheetos PRODUCT -->

        <div id="cheetos-product" class="product-container left-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="cheetos-particles"  class="particles">
                    <div class="cheetos-guitarist"></div>
                    <div class="cheetos-dance"></div>
                    <div class="cheetos-dragon"></div>
                    <!-- <div class="cheetos-big-snacks"></div> -->
                    <div class="cheetos-mobile-app"></div>
                </div>
            </div>
            <div id="cheetos-text-container"  class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/cheetosLogo.png" alt="Cheetos">
                </div>
                <div class="hero-text">
                    <h2>
                        WILD TASTES
                    </h2>
                    <p>Presenting Cheetos new flavors with the latest technology of AR by creating a game that takes you in different and exciting journey in every pack.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- PLAY VIDEO -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- smurfs PRODUCT -->

        <div id="smurfs-product" class="product-container left-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="smurfs-particles"  class="particles">
                    <div class="smurfs-mobile-app"></div>
                    <div class="smurf"></div>
                </div>
            </div>
            <div id="smurfs-text-container"  class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/smurfsLogo.png" alt="Smurfs">
                </div>
                <div class="hero-text">
                    <h2>
                        FIND THE BLUES
                    </h2>
                    <p>If you have an eye for details, this is the game for you...The creation of our Augmented reality game lets you haunt little Smurfs by scanning a magazine.</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- BEGIN THE FUN -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>
        <!-- zeego autostrad PRODUCT -->

        <div id="zeego-autostrad-product" class="product-container right-sided">
            <div class="img-container">
                <div class="bg"></div>
                <div id="zeego-particles"  class="particles">
                    <div class="zeego-road"></div>
                </div>
            </div>
            <div id="zeego-text-container"  class="text-container">
                <div class="product-logo">
                    <img src="../../../../assets/img/production/zeegoLogo.png" alt="Zeego">
                </div>
                <div class="hero-text">
                    <h2>
                        TASTY RIDES
                    </h2>
                    <p>Normal car rides aren’t in anymore, try Zeego’s car rides game and now we’re talking!</p>
                </div>

                <div class="cta">
                    <button type="button" routerLink="/get-quote" mat-flat-button class="magic-hover magic-hover__square">
                        <!-- HOP ON -->
                        GET QUOTE
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <div class="next-container">
            <div class="next-wrapper">
                <p>Next</p>
                <h1>
                    <a routerLink="/social-media" class="magic-hover magic-hover__square">
                        SOCIAL MEDIA
                    </a>
                </h1>
            </div>
        </div>
    </main>
<!-- </div> -->
